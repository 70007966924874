import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InventoryApiService } from "../../Service/InventoryApiService";
import UploadCSV from "../popups/UploadCSV";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import SearchBar from "../Static/SearchBar";
import { useHistory, useLocation } from "react-router";
import ViewDocs from "../popups/ViewDocs";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { Button as BootstrapButton, Form } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";

const MyInventories: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const history = useHistory();
  const [search, setSearch] = useState("");
  const userRole = CookieService.getCookie("userRole");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined && CommonService.isUser() ? CommonService.getCompanyId() : id;
  const [tableData, setTableData] = useState<any[]>([]);
  const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [service, setService] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const controllerRef = useRef<AbortController | null>(null);
  const [activeButton, setActiveButton] = useState<string>("barrels");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filter, setFilter] = useState<any>({
    title: null,
    ownerName: null,
    facility: null,
    age: null,
    fillDate: null,
    quantity: null,
    initialQuantity: null,
    lotNo: null,
    price: null,
    platformFee: null,
  });

  const fetchInventoryData = async (page: any, size: any, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;
      let lastSegment = "";

      if (currentPath.includes("/inventory")) {
        lastSegment = "inventory";
      } else if (currentPath.includes("/available-for-sale")) {
        lastSegment = "available-for-sale";
      } else if (currentPath.includes("/not-available-for-sale")) {
        lastSegment = "not-available-for-sale";
      } else if (currentPath.includes("/available-barrel")) {
        lastSegment = "available-barrel";
      } else if (currentPath.includes("/reserved-barrel")) {
        lastSegment = "reserved-barrel";
      }
      
      //const lastSegment = currentPath.split("/").pop();
      const response: any = await InventoryApiService.getMyInventories(service,
                params,
                lastSegment, page, size, filter, signal, activeButton);

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchInventoryChangeRequests = async () => {
    try {
      setIsLoading(true);
      let response: any;
      if (currentPath === "/user/inventory-change-requests")
        response = await InventoryApiService.getInventoryChangeRequests(
          params,
          false
        );
      else response = await InventoryApiService.getAllInventoryChangeRequests();

      setTableData(Object.values(response.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleServiceDropdown = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setService(event.target.value);
  };

  useEffect(() => {

    if (currentPath === "/user/inventory-change-requests" || currentPath === "/master/inventory-change-requests")
      fetchInventoryChangeRequests();
    else 
      fetchInventoryData(paginationModel.page, paginationModel.pageSize, filter);

  }, []);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString() || ""}>
        <span>{value?.toString() || "-"}</span>
      </Tooltip>
    );
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: value, // Update the specific field
    };
  
    // Update the filter state
    setFilter(tempFilter);
  
    // Load the service requests with updated filters
    fetchInventoryData(paginationModel.page, paginationModel.pageSize, tempFilter);
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Inventory",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.title}
                    onChange={(event) => handleFilterChange(event, "title")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin()
      ? [
     {
      field: "ownerName",
      headerName: "Company Name",
      flex: 1,
      //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.ownerName}
                    onChange={(event) => handleFilterChange(event, "ownerName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    }]
    : []),
    {
      field: "facility",
      headerName: "Facility",
      flex: 1,
      //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.facility}
                    onChange={(event) => handleFilterChange(event, "facility")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.spiritAgeYear || ""}years ${row.spiritAgeMonth || ""}months`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.age}
                    onChange={(event) => handleFilterChange(event, "age")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
        field: "fillDate",
        headerName: "Fill Date",
        headerClassName: "table-header border-0 my-2 h-100",
        flex: 1,
        renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        valueGetter: (value: any, row: any) => {
            const dateTimeString = row.fillDate ? row.fillDate : null;
            if (dateTimeString) {
                return dateTimeString;
            }
        },
        renderHeader: (params) => (
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <input
                    type="date"
                    className="form-control border mt-1"
                    style={{ boxShadow: "none" }}
                    id="birthdate"
                    name="birthdate"
                    placeholder="MM/DD/YYYY"
                    value={filter.fillDate}
                    onChange={(event) => handleFilterChange(event, "fillDate")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
            </div>
        ),
    },
    // {
    //   field: "initialQuantity",
    //   headerName: "Initial Quantity",
    //   // // flex: 1
    //   //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
    //   headerClassName: "table-header border-0 my-2 h-100",
    // },
    {
      field: "quantity",
      headerName: "Quantity for Sales",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        row.isAvailableForSell ? row.quantity : 0,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.quantity}
                    onChange={(event) => handleFilterChange(event, "quantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "isNotAvailableForSell",
      headerName: "Quantity not for Sale",
      flex: 1,
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "info-dropdown",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.initialQuantity}
                    onChange={(event) => handleFilterChange(event, "initialQuantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params) => {
        return (
          <div>
            {!params.row.isAvailableForSell
              ? params.row.quantity +
                params.row.reservedQuantity +
                params.row.soldQuantity
              : 0}
            <Dropdown drop="end" className="d-inline-block">
              <Dropdown.Toggle
                variant="basic"
                id="dropdown-basic"
                className="p-0"
              >
                <InfoIcon sx={{ color: "#6F6F6F" }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  Avilable Quantity{" "}
                  {!params.row.isAvailableForSell ? params.row.quantity : 0}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  Reserved Quantity {params.row.reservedQuantity ?? ""}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Sold Quantity {params.row.soldQuantity ?? ""}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
    {
      field: "lotNo",
      headerName: "Lot ID",
      flex: 1,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.lotNo}
                    onChange={(event) => handleFilterChange(event, "lotNo")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.price}
                    onChange={(event) => handleFilterChange(event, "price")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "platformFee",
      headerName: "Fees",
      flex: 1,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.platformFee}
                    onChange={(event) => handleFilterChange(event, "platformFee")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
  ];

  function routeBack() {
    if (userRole == "ADMIN") history.push("/user/my-warehouse");
    else history.push("/master/inventory-sell-requests");
  }

  // // Filter rows based on search query
  // const filteredRows = tableData.filter(
  //   (row) =>
  //     row.mashBill?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.title?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.price === parseFloat(search) ||
  //     row.barrelNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.ttbType?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData.map((rowData, index) => ({
    ...rowData,
  }));

  const handleRowClick = (params) => {
    const rolePrefix = CommonService.isAdmin() ? "master" : "user";
    const linkPrefix =
      currentPath === "/user/inventory-change-requests" ||
      currentPath === "/master/inventory-change-requests"
        ? "compare-inventory"
        : "edit-inventory";
  
    history.push(`/${rolePrefix}/${linkPrefix}/${params.row.id}`);
  };  

  return (
    <div className="usersList usersList__user" style={{paddingBottom:CommonService.isAdmin() ? 51 : ""}}>
      <div className="usersList__header--wrapper d-flex flex-wrap align-items-center mb-2">
        {CommonService.isAdmin() && (<><div className="back__button me-3" onClick={routeBack}>

          <IconButton
            aria-label="ArrowBackIcon"
            sx={{ backgroundColor: "#FFF", mb: 1 }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>
        </div>

          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0">
              {(currentPath.includes("/inventory") && "Barrels") ||
                (currentPath.includes("/available-for-sale") && "Available For Sale") ||
                (currentPath.includes("/not-available-for-sale") && "Not Available For Sale")}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div></>)}

        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
          <button
            className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 rounded-end-0
                    ${activeButton === "barrels" ? "bg-white" : ""
              }`}
            onClick={() => {
              setActiveButton("barrels");
              fetchInventoryData(paginationModel.page, paginationModel.pageSize, filter);
            }}
          >
            Barrels
          </button>

          <button
            className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1  rounded-start-0
                    ${activeButton === "barrel_lots" ? "bg-white" : ""
              }`}
            onClick={() => {
              setActiveButton("barrel_lots");
              fetchInventoryData(paginationModel.page, paginationModel.pageSize, filter);
            }}
          >
            Barrel Lots
          </button>
        </div>
      </div>
      <div className="user-table-container">
        {/* <div className="form__inner position-relative">
          <Form.Select
            style={{ cursor: "pointer" }}
            value={service}
            className="form-control mb-1"
            onChange={handleServiceDropdown}
          >
            <option value="">Select Service</option>
            <option value="REQUEST_FOR_SALE">Request for Sale</option>
            <option value="REQUEST_FOR_SAMPLE">Request for Sample</option>
            <option value="REQUEST_FOR_OFFER">Request for Offer</option>
            <option value="REQUEST_FOR_SHIPMENT">Request for Shipment</option>
            <option value="REQUEST_FOR_PRIVATE_SELL">
              Request for Private Sell
            </option>
            <option value="REQUEST_FOR_OWNERSHIP">Request for Ownership</option>
            <option value="BARREL_MAINTEENANCE">Barrel Maintenance</option>
          </Form.Select>
        </div> */}
        <div className="userTable">

          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid data-grid__myinventories"
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPaginationModel(newPaginationModel);
              fetchInventoryData(
                  newPaginationModel.page,
                  newPaginationModel.pageSize,
                  filter
              );
            }}
            pageSizeOptions={[5, 10]}
            rowCount={totalCount}
            disableRowSelectionOnClick={true}
            loading={isLoading}
            //onRowClick={handleRowClick}
            //onRowSelectionModelChange={handleSelectionModelChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MyInventories;
