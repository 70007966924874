import { Url } from "../Constants/Url"
import AxiosApiService from "./AxiosService"

export const SupportApiService = {
    getAllServiceRequests, getAllServiceType, fetchCustomerServiceRequestHistory, fetchCustomerServiceRequestDocs,downloadCustomerServiceRequestDOC, getCustomerServiceRequest,deleteCustomerServiceRequest, updateCustomerServiceRequestStatus,uploadCustomerServiceRequestDocs,getCustomerServiceRequestForEditing,deleteCustomerServiceRequestDoc, fetchCustomerServiceRequestDocsForEditing
}
async function getAllServiceRequests(companyId: any, page: any, size: any, filter: any, signal: AbortSignal) {
    try {
      const response = await AxiosApiService.getWithParams<any>(Url.GET_CUSTOMER_SUPPORT_REQUESTS, {
        params: {
          companyId: companyId,
          page: page,
          size: size,
          name: filter.name,
          status: filter.status,
          createdDate: filter.createdDate,
          requestNumber: filter.requestNumber,
          requestType: filter.requestType,
          companyName: filter.companyName
        },
        signal: signal // Pass the signal to the Axios request
      });
  
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was cancelled');
      } else {
        console.error('Error fetching service requests:', error);
        throw error; // Re-throw the error to handle it in the calling function
      }
    }
  }
  

async function getAllServiceType() {
    return await AxiosApiService.get<any>(Url.GET_ALL_SERVICE_TYPE);
}

async function fetchCustomerServiceRequestHistory(customerServiceRequestId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_HISTORY + '/' + customerServiceRequestId);
}

async function fetchCustomerServiceRequestDocs(customerServiceRequestId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_DOCS + '/' + customerServiceRequestId);
}

async function fetchCustomerServiceRequestDocsForEditing(customerServiceRequestId:any) {
  return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_DOCS_FOR_EDITING + '/' + customerServiceRequestId);
}

async function downloadCustomerServiceRequestDOC(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_CUSTOMER_SERVICE_REQUEST_DOC, downloadDoc);
}

async function getCustomerServiceRequest(customerServiceRequestId: any) {
    return await AxiosApiService.get<any>(Url.GET_CUSTOMER_SERVICE_REQUEST + '/' + customerServiceRequestId);
}

async function deleteCustomerServiceRequest(customerServiceRequestId: any) {
    return await AxiosApiService.get(Url.DELETE_CUSTOMER_SUPPORT_REQUEST + '/' + customerServiceRequestId);
}

async function deleteCustomerServiceRequestDoc(id: any) {
  return await AxiosApiService.get(Url.DELETE_CUSTOMER_SUPPORT_REQUEST_DOC + '/' + id);
}

async function updateCustomerServiceRequestStatus(customerServiceRequestId: any , status:any) {
    return await AxiosApiService.getWithParams(Url.UPDATE_CUSTOMER_SUPPORT_REQUEST + '/' + customerServiceRequestId, {
        params: {
            status : status
        }
    })
}

async function getCustomerServiceRequestForEditing(customerServiceRequestId: any) {
  return await AxiosApiService.get<any>(Url.GET_CUSTOMER_SERVICE_REQUEST_FOR_EDITING + '/' + customerServiceRequestId);
}

async function uploadCustomerServiceRequestDocs(fileUpload: any) {
  return await AxiosApiService.post<any>(Url.UPLOAD_CUSTOMER_SERVICE_REQUEST_DOC, fileUpload)
}