import { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import { useHistory } from "react-router";
import "./CustomerSupport.scss";
import { InventoryApiService } from "../../Service/InventoryApiService";

const CustomerSupport = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedInventory, setSelectedInventory] = useState("");
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [inventoriesList, setInventoriesList] = useState([]);
  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  

  const history = useHistory();
  
  useEffect(() => {
    fetchAllServices();
  }, []);

  const fetchAllServices = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getAllServiceType();
      setServices(response.data);  
    } catch(error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFieldsByServiceType = async (serviceTypeId) => {
    try {
      setIsLoading(true);
      const response = await Service.getFieldByServiceType(serviceTypeId);
      setFields(response.data);
      
      // Initialize form data with default values
      const initialData = {};
      const initialError = {};
      response.data.forEach(field => {
        if(field.dataType == 'radio' || field.dataType == 'number' || field.dataType == 'text') {
            initialData[field.serviceTypeServiceFieldId] = field.dataType === 'radio' ? false : '';
        }
        if(field.mandatory) {
          initialError[field.serviceTypeServiceFieldId] = '';
        }
        
      });
      setFormData(initialData);
      setErrors(initialError);
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchExistingCompanies = async () => {
    try {
      const response = await Service.fetchCompaniesList();
      setCompaniesList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchInventoriesByCompany = async (companyId:any) => {
    try {
        const response = await InventoryApiService.getInventoryListByCompanyId(companyId);
        setInventoriesList(response.data);
      } catch (error) {
        console.error("Error while calling api:", error);
      }
  }

  const fetchUsersByCompany = async (companyId:any) => {
    try {
        const response = await Service.fetchUsersList(companyId);
        setUsersList(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
  }

  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    if (serviceId) {
      
        setFormData(
          formData => ({ ...formData, serviceId: serviceId })   
        )
    
      fetchFieldsByServiceType(serviceId);
      //set this serviceId into the formData
      if (serviceId) {
        setFormData(
          formData => ({ ...formData, serviceId: serviceId })   
        )
      }
      fetchExistingCompanies();

    } else {
      setFields([]);
      setFormData({});
    }
  };

  const handleCompanyChange = (value:any, serviceTypeServiceFieldId:any) => {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));
    const companyId = value;
    setSelectedCompany(companyId);

    fetchUsersByCompany(companyId);
    fetchInventoriesByCompany(companyId);
    
  }

  const handleUserChange = (value:any, serviceTypeServiceFieldId:any) => {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));
    const userId = value;
    setSelectedUser(userId);
    
  }


  const handleInventoryChange = (value:any, serviceTypeServiceFieldId:any) => {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));

    const inventoryId = value;
    setSelectedInventory(inventoryId);
    
  }
  

  const handleFieldChange = (value, serviceTypeServiceFieldId) => {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));

    let processedValue = value;

    // Convert "yes"/"no" to true/false
    if (value === 'yes') {
      processedValue = true;
    } else if (value === 'no') {
      processedValue = false;
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: processedValue,
    }));
  };

  const isValidate = () => {
    let isValid = true;
  
    const newErrors: Record<string, string> = {};
    
    fields.forEach(field => {
      if (field.mandatory) {
        if (errors[field.serviceTypeServiceFieldId] ||
          ((field.fieldName === 'company_dropdown' && !selectedCompany) ||
          (field.fieldName === 'user_dropdown' && !selectedUser) ||
          (field.fieldName === 'product_dropdown' && !selectedInventory) ||
          (field.fieldName === 'price' && (price <= 0) || field.fieldName === 'quantity' && (quantity <= 0)) ||
          (formData[field.serviceTypeServiceFieldId] === ''))
        ) {
          newErrors[field.serviceTypeServiceFieldId] = 'error';
          isValid = false;
        }
      }
    })
    
    setErrors(newErrors);
    return isValid;
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    
     // Transform formData into a list of objects with id and value
     const dynamicFields = Object.keys(formData).map((key) => ({
      id: key,
      value: formData[key],
    }));

    if(isValidate()) {

    // Prepare the object to send
    const requestData = {
      dynamicFields,
      selectedCompanyId: selectedCompany,
      selectedUserId: selectedUser,
      selectedInventoryId: selectedInventory,
      selectedServiceId: selectedService,
      price:price,
      quantity:quantity
    };

    // Log the form data to the console
    console.log('Form data to submit:', requestData);

    //call the api
    try {
      const response = Service.saveCustomerSupportRequest(requestData);
      console.log("Data added successfully");
    } catch(error) {
      console.log("error occurred while api calling " + error);
    }

  }

};

const handleQuantityChange = (quantity:any, serviceTypeServiceFieldId:any) => {
  setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));

  const value = Number(quantity);
  setQuantity(value);
};

const handlePriceChange = (price:any, serviceTypeServiceFieldId:any) => {
  setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: '' }));

  const value = Number(price);
  setPrice(value);
};

const handleNumberOnBlur = (value:any, serviceTypeServiceFieldId:any) => {
  console.log("this is my value  "+ value + " and id " + serviceTypeServiceFieldId);
  const numValue = Number(value);
  if (numValue <= 0 || !value) {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: 'error' }));
  }
    
}

const handleFieldBlur = (value:any, serviceTypeServiceFieldId:any) => {
  console.log("this is my value  "+ value + " and id " + serviceTypeServiceFieldId);

  if(!value || value == null || value?.trim() == "") {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: 'error' }));
  }
}

const handleCompanyBlur = (serviceTypeServiceFieldId:any) => {

  if(!selectedCompany) {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: 'error' }));
  }
}

const handleInventoryBlur = (serviceTypeServiceFieldId:any) => {
  if(!selectedInventory) {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: 'error' }));
  }
}

const handleUserBlur = (serviceTypeServiceFieldId:any) => {
  if(!selectedUser) {
    setErrors(prev => ({ ...prev, [serviceTypeServiceFieldId]: 'error' }));
  }
}

  const renderField = (field) => {
    switch (field.dataType) {
    
  case 'company_dropdown':
        return (
    <div className="form__inner mb-6">
      <label htmlFor="service-dropdown" className="block mb-2 form-label">
      {field.fieldName}
      {field.mandatory && <span className="star-required text-danger">*</span>}
      </label>
      <select
        id="company-dropdown"
        value={selectedCompany}
        onChange={(e) => handleCompanyChange(e.target.value, field.serviceTypeServiceFieldId)}
        onBlur = {() => handleCompanyBlur(field.serviceTypeServiceFieldId)}
        className={`select-dropdown ${
          (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
        }`}
      >
        <option value="">Select Company</option>
        {companiesList.map((company) => (
          <option key={company.id} value={company.id}>
            {company.companyName}
          </option>
        ))}
      </select>

      {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">Please select a company</span>}

    </div>
        );
      case 'user_dropdown':
        return (
            <div className="mb-6 form__inner">
              <label htmlFor="service-dropdown" className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && <span className="star-required text-danger">*</span>}
              </label>
              <select
                id="company-dropdown"
                value={selectedUser}
                onChange={(e) => handleUserChange(e.target.value, field.serviceTypeServiceFieldId)}
                onBlur = {() => handleUserBlur(field.serviceTypeServiceFieldId)}
                className={`select-dropdown ${
                  (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
                }`}
              >
                <option value="">Select User</option>
                {usersList.map((user) => (
                  <option key={user.userId} value={user.userId}>
                    {user.userName}
                  </option>
                ))}
              </select>
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">Please select a user </span>}

            </div>
                );
      case 'product_dropdown':
        return (
          <div className="mb-6 form__inner">
          <label htmlFor="service-dropdown" className="block mb-2 form-label">
          {field.fieldName}
          {field.mandatory && <span className="star-required text-danger">*</span>}
          </label>
          <select
            id="company-dropdown"
            value={selectedInventory}
            onChange={(e) => handleInventoryChange(e.target.value, field.serviceTypeServiceFieldId)}
            onBlur={() => handleInventoryBlur(field.serviceTypeServiceFieldId)}
            className={`select-dropdown ${
              (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
            }`}
          >
            <option value="">Select Product</option>
            {inventoriesList.map((inventory) => (
              <option key={inventory.inventoryId} value={inventory.inventoryId}>
                {inventory?.inventoryName}
              </option>
            ))}
          </select>
          {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">Please select a product</span>}
        </div>
        );
        case 'price':
          return (
            <div key={field.fieldName} className="mb-4 form__inner">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && <span className="star-required text-danger">*</span>}
              </label>
              <input
                type="number"
                value={price}
                onChange={(e) => handlePriceChange(e.target.value, field.serviceTypeServiceFieldId)}
                onBlur={(e) => handleNumberOnBlur(e.target.value,field.serviceTypeServiceFieldId)}
                className={`form-control ${
                  (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
                }`}
              />

             {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">This field is required </span>}
            </div>
          );
          case 'quantity':
            return (
              <div key={field.fieldName} className="mb-4 form__inner">
                <label className="block mb-2 form-label">
                  {field.fieldName}
                  {field.mandatory && <span className="star-required text-danger">*</span>}
                </label>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => handleQuantityChange(e.target.value,field.serviceTypeServiceFieldId)}
                  onBlur={(e) => handleNumberOnBlur(e.target.value,field.serviceTypeServiceFieldId)}
                  className={`form-control ${
                    (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
                  }`}
                />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">This field is required </span>}
              </div>

            );
      case 'number':
        return (
          <div key={field.fieldName} className="mb-4 form__inner">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && <span className="star-required text-danger">*</span>}
            </label>
            <input
              type="number"
              value={formData[field.serviceTypeServiceFieldId] || ''}
              onChange={(e) => handleFieldChange(e.target.value, field.serviceTypeServiceFieldId)}
              onBlur={(e) => handleNumberOnBlur(e.target.value,field.serviceTypeServiceFieldId)}
              className={`form-control ${
                (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">This field is required </span>}
          </div>
        );
      case 'radio':
        return (
          <div key={field.fieldName} className="mb-4 form__inner">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && <span className="star-required text-danger">*</span>}
            </label>
        

            <div className="d-flex align-items-center">
                      <div className="d-inline-flex">
                        <input
                          type="radio"
                          name={field.fieldName}
                          id={`${field.fieldName}-no`}
                          className="custom-radio"
                          value="no"
                          checked={formData[field.serviceTypeServiceFieldId] === false}
                          onChange={(e) => handleFieldChange(e.target.value, field.serviceTypeServiceFieldId)}
                        />
                        <label
                          className="custom-radio-label"
                          htmlFor={`${field.fieldName}-no`}
                        >
                          No
                        </label>
                      </div>
                      <div className="d-inline-flex ms-4 me-2">
                        <input
                          type="radio"
                          name={field.fieldName}
                          id={`${field.fieldName}-yes`}
                          className="custom-radio"
                          value="yes"
                          checked={formData[field.serviceTypeServiceFieldId] === true}
                          onChange={(e) => handleFieldChange(e.target.value, field.serviceTypeServiceFieldId)}
                        />
                        <label
                          className="custom-radio-label"
                          htmlFor={`${field.fieldName}-yes`}
                        >
                          Yes
                        </label>
                      </div>
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">This field is required </span>}
                    </div>
          </div>
        );
      case 'text':
        return (
          <div key={field.fieldName} className="mb-4 form__inner">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && <span className="star-required text-danger">*</span>}
            </label>
            <input
              type="text"
              value={formData[field.serviceTypeServiceFieldId]}
              onChange={(e) => handleFieldChange(e.target.value, field.serviceTypeServiceFieldId)}
              onBlur ={(e) => handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)}
              className={`form-control ${
                (field.mandatory && errors[field.serviceTypeServiceFieldId]) ? "is_invalid" : ""
              }`}
            />
          {field.mandatory && errors[field.serviceTypeServiceFieldId] && <span className="error-message ">This field is required </span>}

          </div>
        );
    }
  };

  return (
  <div className="p-4">
  <div className="d-flex align-items-center mb-3 px-3">
    
    <h3 className="text-xl font-semibold">New Service Request</h3>
  </div>

  <div className="p-0">
  <form onSubmit={handleSubmit} className="row d-flex mx-auto py-5">
    <div className="mb-6 form__inner">
      <label htmlFor="service-dropdown" className="block mb-2 form-label">
        Select a Service type
        <span className="star-required text-danger">*</span>
      </label>
      <select
        id="service-dropdown"
        value={selectedService}
        onChange={handleServiceChange}
        className="select-dropdown"
        required
      >
        <option value="">Select</option>
        {services.map((service) => (
          <option key={service.id} value={service.id}>
            {service.serviceName}
          </option>
        ))}
      </select>
    </div>

    {isLoading && (
      <div className="text-center py-4">Loading...</div>
    )}

    {!isLoading && fields.length > 0 && (
      <div className="space-y-4">
        {fields.map(field => renderField(field))}
        <div className="p-4 d-flex">
          <button
            className="secondary-btn w-100 m-4"
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn w-100 m-4"
          >
            Submit Request
          </button>
        </div>
      </div>
    )}
  </form>
  </div>
  </div>
);
};

export default CustomerSupport;