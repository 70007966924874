import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NoteIcon from "../../assets/images/icons/Note.svg";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import DownloadSimpleIcon from "../../assets/images/icons/DownloadSimple.svg";
import { AnyARecord } from "dns";
import { red } from "@mui/material/colors";
import { isThisTypeNode } from "typescript";
import { CookieService } from "../../Service/CookieService";
import CloudArrowUp from "../../assets/images/icons/CloudArrowUp.svg";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
// import Spinner from "../Common/Spinner";
import Spinner from 'react-bootstrap/Spinner';
import { InventoryApiService } from "../../Service/InventoryApiService";
import { Form } from "react-bootstrap";
import { error } from "console";
import { ErrorSharp } from "@mui/icons-material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// CSS for custom scrollbar
const styles = `
  .warning-list {
    width: 100%;
    max-width: 100%;
    background-color: #fff; /* Matches your background */
    max-height: 50vh;
    overflow-y: auto;
  }

  .warning-list::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
  }

  .warning-list::-webkit-scrollbar-thumb {
    background: #496673;
    border-radius: 10px;
  }

  .warning-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
`;

type FormValue = {
  platformFee: number;
  feeType: string;
};
export default function UploadCSV({ fetchData, csvId }: any) {
  useEffect(() => {
    console.log("inside upload csv");
    fetchCompaniesList();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    if (!isLoading) {
      setIsInvited(false);
      setIsValidated(false);

      setRelocationRequired("");
      setThirdPartyStorage("");
      setAccountTransfer("");
      setMakeAnOffer("");
      setAreYouOwner("yes");
      setOwnerName("");
      setFile(null);
      setTTBFile([]);
      fetchData();
      setShow(false);
      setSelectedCompany("");
      setFeeData({
        feeType: 'FLAT',
      });
      errors.areYouOwner = "";
      errors.ownerName = "";
      errors.accountTransfer = "";
      errors.offerAccepted = "";
      errors.relocateBarrel = "";
      errors.thirdPartyStorage = "";
      errors.feePerBarrel = "";
      errors.companySelected = "";
    }
  };
  type FormValue = {
    platformFee?: number; // Making platformFee optional
    feeType: string;
  };
  const handleShow = () => setShow(true);
  const [isInvited, setIsInvited] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [feeData, setFeeData] = useState<FormValue>({
    feeType: "FLAT",
  });
  const [ownerName, setOwnerName] = useState("");
  const [relocationRequired, setRelocationRequired] = useState("");
  const [thirdPartyStorage, setThirdPartyStorage] = useState("");
  const [makeAnOffer, setMakeAnOffer] = useState("");
  const [accountTransfer, setAccountTransfer] = useState("");
  const [areYouOwner, setAreYouOwner] = useState("yes");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<any | null>(null);
  const [ttbFile, setTTBFile] = useState<File[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const [validationsList, setValidationsList] = useState<any[]>([]);

  // Inject the custom scrollbar styles into the DOM
  React.useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.textContent = styles;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const validateFileType = (file: File): boolean => {
    const validTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    
    const validExtensions = ['.csv', '.xls', '.xlsx'];
    
    // Check file extension
    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
    const hasValidExtension = validExtensions.includes(fileExtension);
  
    // Check MIME type
    const hasValidMimeType = validTypes.includes(file.type);
  
    return hasValidMimeType && hasValidExtension;
  };



  // For your file input handler
const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const selectedFile = event.target.files?.[0];
  if (selectedFile) {
    if (validateFileType(selectedFile)) {
      setFile(selectedFile);
    } else {
      event.target.value = ''; // Clear the file input
      CommonService.Toast.fire({
        title: "Please select a valid inventory file format either CSV or Excel (XLSX). You can add non-CSV or non-Excel files as supporting documents.",
        icon: "error",
      });    }
  }
};

// For your drag and drop handler
const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();

  console.log("this is drop handler");

  let file: File | null = null;
  if (event.dataTransfer.items) {
    const item = Array.from(event.dataTransfer.items).find(
      item => item.kind === 'file'
    );
    if (item) {
      file = item.getAsFile();
    }
  } else {
    file = event.dataTransfer.files[0];
  }

  if (file) {
    if (validateFileType(file)) {
      setFile(file);
        } else {
          CommonService.Toast.fire({
            title: "Please select a valid inventory file format either CSV or Excel (XLSX). You can add non-CSV or non-Excel files as supporting documents.",
            icon: "error",
          });    }
  }
};




  const onDragOver = (ev: any) => ev.preventDefault();

  const onTTBDropHandler = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setTTBFile((prevFiles: any) => [...prevFiles, ...droppedFiles]);
  };

  /**
   * @param {any} event
   */
  function handleFileUpload(event: any) {
    console.log("csv - " + event);
    setFile(event.target.files[0]);
  }

  /**
   * @param {any} event
   */
  function handleTTBFileUpload(event: any) {
    const newFiles = Array.from(event.target.files); // Convert FileList to an array
    console.log(newFiles);
    setTTBFile((prevFiles: any) => [...prevFiles, ...newFiles]); // Append new files to existing array
  }

  const handleDeleteFile = (index: number) => {
    setTTBFile((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  const handleOwnerSwitchButton = (value: any) => {
    errors.areYouOwner = "";

    if (value && value == "yes") {
      setOwnerName(CommonService.getCompanyName());
      errors.ownerName = "";
    } else
      setOwnerName("");

    setAreYouOwner(value);
  };

  const handleSwitchButton = (value: any) => {
    errors.relocateBarrel = "";
    setRelocationRequired(value);
  };

  const handle3partySwitchButton = (value: any) => {
    errors.thirdPartyStorage = "";
    setThirdPartyStorage(value);
  };

  const handleOfferSwitchButton = (value: any) => {
    errors.offerAccepted = "";
    setMakeAnOffer(value);
  };

  const handleAccountTransferButton = (value: any) => {
    errors.accountTransfer = "";
    setAccountTransfer(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (Number(value.trim()) >= 0) {
      errors.feePerBarrel = "";
    }
    setFeeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOwnerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value) errors.ownerName = "";

    setOwnerName(value); // Directly set the new value
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    errors.companySelected = "";
    const selectedCompanyId = event.target.value;
    
    if (selectedCompanyId) {
      
      const selectedCompany = companiesList.find(company => company.id === selectedCompanyId);
      setSelectedCompany(selectedCompanyId);

      if (selectedCompany && areYouOwner === "yes") {
        // Set the selected company ID and company name
        setOwnerName(selectedCompany.companyName);
      }

    } else {
      // Reset if no company is selected
      setSelectedCompany("");
      setOwnerName("");
    }
  };

  const handleFeeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFeeData({ ...feeData, feeType: e.target.value });
  };

  const fetchCompaniesList = async () => {
    const response: any = await Service.fetchCompaniesList();
    setCompaniesList(response.data);
  };

  const downloadInventoryTemplate = async () => {
    try {
      const response: any = await InventoryApiService.downloadInventoryTemplate();
      // Assuming response.data is the blob data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = 'InventoryTemplate.xlsx'; // Specify the download filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [errors, setErrors] = useState({
    ownerName: "",
    areYouOwner: "",
    relocateBarrel: "",
    thirdPartyStorage: "",
    offerAccepted: "",
    accountTransfer: "",
    companySelected: "",
    feePerBarrel: "",
  });

  //validate all the option, user select the option or not
  const validateRequiredFields = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!relocationRequired) {
      newErrors.relocateBarrel = "Please select an option";
      isValid = false;
    }

    if (!thirdPartyStorage) {
      newErrors.thirdPartyStorage = "Please select an option";
      isValid = false;
    }

    if (!makeAnOffer) {
      newErrors.offerAccepted = "Please select an option";
      isValid = false;
    }

    if (!accountTransfer) {
      newErrors.accountTransfer = "Please select an option";
      isValid = false;
    }

    if (!areYouOwner) {
      newErrors.areYouOwner = "Please select an option";
      isValid = false;
    }

    if (!selectedCompany) {
      newErrors.companySelected = "Please select company";
      isValid = false;
    }

    if (!feeData.platformFee || Number(feeData.platformFee < 0)) {
      newErrors.feePerBarrel = "Please enter fee($)/barrel";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendInvite = async () => {
    if (await validateRequiredFields()) {
      setIsLoading(true);
      console.log("come inside the send Invite");
      console.log(
        thirdPartyStorage,
        accountTransfer,
        makeAnOffer,
        relocationRequired
      );
      try {
        if (file) {
          const formData = new FormData();
          // if (!relocationRequired || !thirdPartyStorage) {
          //   setIsLoading(false);
          //   console.log("validation fail");
          //   return;
          // }
          let CSVfileUploadRequest = {
            id: csvId,
            description: "submitted inventory sell request",
            isRelocationRequired: relocationRequired === "yes" ? true : false,
            isThirdPartyStorage: thirdPartyStorage === "yes" ? true : false,
            isMakeAnOffer: makeAnOffer === "yes" ? true : false,
            isAccountTransfer: accountTransfer === "yes" ? true : false,
            areYouOwner: areYouOwner === "yes" ? true : false,
            ownerName: ownerName ? ownerName : null,
            ...(feeData),
            ...({ companyId: selectedCompany }),
          };

          console.log(CSVfileUploadRequest);

          formData.append(
            "param",
            new Blob([JSON.stringify(CSVfileUploadRequest)], {
              type: "application/json",
            })
          );

          if (file) {
            formData.append("csvfile", file);
          }
          // Check if ttbFile is defined and is an array
          if (ttbFile && Array.isArray(ttbFile)) {
            ttbFile.forEach(function (file: any) {
              formData.append("ttbfile[]", file);
            });
          }

          console.log(formData);

          const response = await Service.uploadAndSave(formData);
          if (response) {
            setIsLoading(false);
            setIsInvited(true);
            setIsValidated(false);

            
              const updatedCSV = {
                id: response.data,
                status: "PUBLISHED",
                ...feeData,
              };

              const inventoryResponse =
                await InventoryApiService.updateInventoryCSV(updatedCSV);
              console.log(inventoryResponse);
            
            fetchData();
          }
        } else {
          setIsLoading(false);
          CommonService.Toast.fire({
            title: "Please select an Inventory file",
            icon: "error",
          });
        }
      } catch (error: any) {
        setIsLoading(false);
        setIsInvited(false);

        console.log(error);

        // Check if the error is an Axios error and has a response
        if (error.response) {
          // The backend error message should be inside error.response.data.message

          console.log(error?.response?.data?.errorMessage);
          const errorMessage =
            error?.response?.data?.errorMessage || "Failed to upload CSV";

          // Log the error for debugging
          console.log("Error message from server: ", errorMessage);

          // Show the error in a toaster
          if (error?.response?.data?.data != null && errorMessage.includes("Error occurred while validating")) {
            CommonService.Toast.fire({
              title: "Please Upload a valid file",
              icon: "error",
            });

            setValidationsList(error?.response?.data?.data);
            setIsValidated(true);
          } else if (errorMessage.includes("Invalid Csv File")) {
            CommonService.Toast.fire({
              title: "Please Upload a valid CSV file",
              icon: "error",
            });
          } else if (errorMessage.includes("Excel file")) {
            CommonService.Toast.fire({
              title: "Please Upload a valid excel file",
              icon: "error",
            });
          } else if (errorMessage.includes("UNSUPPORTED_MEDIA_TYPE")) {
            CommonService.Toast.fire({
              title: "Please select a valid inventory file format either CSV or Excel (XLSX). You can add non-CSV or non-Excel files as supporting documents.",
              icon: "error",
            });
          } else {
            CommonService.Toast.fire({
              title: errorMessage,
              icon: "error",
            });
          }
        } else {
          // If there is no response, it's a different type of error (e.g., network error)
          console.log("Error", error.message);
          CommonService.Toast.fire({
            title: "An unexpected error occurred",
            icon: "error",
          });
        }
      }
    }
  };

  return (
    <>
      <button className="primary-btn d-inline-flex" onClick={handleShow}>
        Submit Sell Request
        <FileUploadOutlinedIcon sx={{ ml: 1 }} />
      </button>
      <Modal
        size="lg"
        className="modal__wd modal__wd--submitSellReq"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title className="mb-1">Submit Sell Request</Modal.Title>
          {!isInvited && (
            <p className="modal-text text-start mb-0">
              To add a file, drag and drop in the area below or select the
              "Select file" button.
            </p>
          )}
          {isInvited && (
            <p className="modal-text text-start">
              Your submission has been submitted successfully, You will receive
              a notification upon its completion.
            </p>
          )}
          <Button
            variant="close"
            className="d-inline-flex justify-content-center align-items-center"
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Modal.Header>
        {!isInvited && (
          <>
            <Modal.Body>
              {(!isValidated && !isLoading) && (
                <div>
                  <div className="modal-body__upload-file row border-0 g-4 g-md-3">
                    <div className="col-md-6">
                      <div className="file__upload--container">
                        <div
                          className="file__upload--dropzone d-flex flex-column text-center mb-2 pb-2"
                          id="drop_zone"
                          onDrop={onDropHandler}
                          onDragOver={onDragOver}
                        >
                          <div>
                            <img src={CloudArrowUp} alt="CloudArrowUp" />
                            <br />
                            <span className="fw-bold">
                              Drag & Drop to Upload File
                            </span>
                          </div>
                          <div className="my-2 fw-bold">OR</div>
                          <div className="d-flex justify-content-center flex-wrap">
                            <span className="d-inline-block">
                              <label
                                htmlFor={"file_picker"}
                                className="primary-btn fp-label fw-bold"
                              >
                                Select file
                              </label>
                              <input
                                id="file_picker"
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                style={{ display: "none" }}
                              ></input>
                            </span>
                            <button className="btn d-inline-flex align-items-center bg-transparent px-3 z-1 fw-medium"
                              onClick={downloadInventoryTemplate}
                              disabled={isLoading}
                            >
                              <img src={DownloadSimpleIcon} alt="DownloadSimpleIcon" className="me-1" />
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: '#496673', fontWeight: 600, cursor: 'pointer' }}
                              >
                                Download Template
                              </Typography>
                            </button>
                          </div>
                        </div>
                        {file?.name && (
                          <>
                            {/* <LinearProgress variant="determinate" value={50} /> */}
                            <div className="file__upload--info d-inline-flex align-items-center mb-1">
                              {/* <div className="me-2">
                        <DescriptionOutlinedIcon />
                      </div> */}
                              <div className="p-1 d-flex">
                                <img
                                  src={NoteIcon}
                                  alt="NoteIcon"
                                  className="file-icon me-2"
                                />
                                <div className="file-name me-2" key={file?.name}>
                                  {file?.name}
                                </div>
                              </div>
                              <img
                                src={TrashIcon}
                                alt="TrashIcon"
                                className="file-delete"
                                onClick={() => setFile(null)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mt-3 form__inner">
                          <div className="basic-faq mt-2">
                           
                            
                              <span
                              className="question-text d-block mb-2"
                              style={{ fontSize: "15.6px" }}
                            >
                              Is selected company is the owner?{" "}
                              <span className="text-danger">*</span>
                            </span>
                          

                            <div className="custom-radio-container">
                              <div className="d-inline-flex me-2">
                                <input
                                  type="radio"
                                  className="custom-radio"
                                  name="isOwner"
                                  id="isOwnerId1"
                                  value="yes"
                                  checked={areYouOwner === "yes"}
                                  onChange={() => handleOwnerSwitchButton("yes")}
                                />
                                <label
                                  className="custom-radio-label"
                                  htmlFor="isOwnerId1"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="d-inline-flex">
                                <input
                                  type="radio"
                                  className="custom-radio"
                                  name="isOwner"
                                  id="isOwnerId2"
                                  value="no"
                                  checked={areYouOwner === "no"}
                                  onChange={() => handleOwnerSwitchButton("no")}
                                />
                                <label
                                  className="custom-radio-label"
                                  htmlFor="isOwnerId2"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            {errors.areYouOwner && (
                              <div className="error-message">{errors.areYouOwner}</div>
                            )}
                          </div>

                        <div className="basic-faq mt-2">
                          <span
                            className="question-text d-block mb-2"
                            style={{ fontSize: "15.6px" }}
                          >
                            Is there a requirement to relocate the barrels?{" "}
                            <span className="text-danger">*</span>
                          </span>
                          <div className="custom-radio-container">
                            <div className="d-inline-flex me-2">
                              <input
                                type="radio"
                                className="custom-radio"
                                name="relocationRequired"
                                id="relocationRequired1"
                                value="yes"
                                checked={relocationRequired === "yes"}
                                onChange={() => handleSwitchButton("yes")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="relocationRequired1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="d-inline-flex">
                              <input
                                type="radio"
                                className="custom-radio"
                                name="relocationRequired"
                                id="relocationRequired2"
                                value="no"
                                checked={relocationRequired === "no"}
                                onChange={() => handleSwitchButton("no")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="relocationRequired2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {errors.relocateBarrel && (
                            <div className="error-message">
                              {errors.relocateBarrel}
                            </div>
                          )}
                        </div>

                        <div className="basic-faq mt-2">
                          <span className="question-text d-block mb-2">
                            Are inventories stored at 3rd party facility?{" "}
                            <span className="text-danger">*</span>
                          </span>
                          <div className="custom-radio-container">
                            <div className="d-inline-flex me-2">
                              <input
                                type="radio"
                                className="custom-radio"
                                name="thirdPartyStorage"
                                id="thirdPartyStorage1"
                                value="yes"
                                checked={thirdPartyStorage === "yes"}
                                onChange={() => handle3partySwitchButton("yes")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="thirdPartyStorage1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="d-inline-flex">
                              <input
                                type="radio"
                                className="custom-radio"
                                name="thirdPartyStorage"
                                id="thirdPartyStorage2"
                                value="no"
                                checked={thirdPartyStorage === "no"}
                                onChange={() => handle3partySwitchButton("no")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="thirdPartyStorage2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {errors.thirdPartyStorage && (
                            <div className="error-message">
                              {errors.thirdPartyStorage}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="file__upload--container">
                        <div
                          className="file__upload--dropzone d-flex flex-column text-center mb-2 pb-2"
                          id="drop_zone"
                          onDrop={onTTBDropHandler}
                          onDragOver={onDragOver}
                        >
                          <div>
                            <img src={CloudArrowUp} alt="CloudArrowUp" />
                            <br />
                            <span className="fw-bold">
                              Drag & Drop to Upload TTB Files
                            </span>
                          </div>
                          <div className="my-2 fw-bold">OR</div>
                          <label
                            htmlFor={"multiple_file_picker"}
                            className="primary-btn fp-label fw-bold"
                          >
                            Select file
                          </label>
                          <input
                            id="multiple_file_picker"
                            type="file"
                            onChange={handleTTBFileUpload}
                            accept="text"
                            style={{ display: "none" }}
                            multiple
                          ></input>
                        </div>
                        {ttbFile.map((item, index) => (
                          <div
                            className="file__upload--info d-inline-flex align-items-center mb-1 me-2"
                            key={index}
                          >
                            <div className="p-1 d-flex">
                              <img
                                src={NoteIcon}
                                alt="NoteIcon"
                                className="file-icon me-2"
                              />

                              <div className="file-name me-3">{item.name}</div>
                            </div>
                            <img
                              src={TrashIcon}
                              alt="TrashIcon"
                              className="file-delete"
                              onClick={() => handleDeleteFile(index)}
                            />
                          </div>
                        ))}

                        {/* <div className="file__upload--info d-inline-flex align-items-center">
                          
                          <div className="p-1 d-flex">
                            <img src={NoteIcon} alt="NoteIcon" className="file-icon me-2" />
                            <div
                              className="file-name me-2"
                              key={file?.name}
                            >
                              {file?.name}
                            </div>
                          </div>
                          <img src={TrashIcon} alt="TrashIcon" className="file-delete" onClick={() => setFile(null)} />
                        </div> */}
                      </div>
                      <div className="mt-3 form__inner">
                        

                    
                          <div className="basic-faq mt-2 disabled-div">
                            <label
                            htmlFor="ownerNameInput"
                            className="form-label fw-semibold" >
                                {areYouOwner == "" && ("Select owner")}
                                {areYouOwner == "yes" && ( "Selected company is the owner of uploaded inventory")}
                                {areYouOwner == "no" && ( "The owner name is determined by the information associated with the uploaded file")}
                            </label>
                          </div>
                        

                        <div className="basic-faq mt-2">
                          <span className="question-text d-block mb-2">
                            Will offers be accepted for these inventories?{" "}
                            <span className="text-danger">*</span>
                          </span>
                          <div className="custom-radio-container">
                            <div className="d-inline-flex me-2">
                              <input
                                type="radio"
                                name="makeAnOffer"
                                id="makeAnOffer1"
                                className="custom-radio"
                                value="yes"
                                checked={makeAnOffer === "yes"}
                                onChange={() => handleOfferSwitchButton("yes")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="makeAnOffer1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="d-inline-flex">
                              <input
                                type="radio"
                                name="makeAnOffer"
                                id="makeAnOffer2"
                                className="custom-radio"
                                value="no"
                                checked={makeAnOffer === "no"}
                                onChange={() => handleOfferSwitchButton("no")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="makeAnOffer2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {errors.offerAccepted && (
                            <div className="error-message">
                              {errors.offerAccepted}
                            </div>
                          )}
                        </div>

                        <div className="basic-faq mt-2">
                          <span className="question-text d-block mb-2">
                            Is account transfer required?{" "}
                            <span className="text-danger">*</span>
                          </span>
                          <div className="custom-radio-container">
                            <div className="d-inline-flex me-2">
                              <input
                                type="radio"
                                name="accountTransfer"
                                id="accountTransfer1"
                                className="custom-radio"
                                value="yes"
                                checked={accountTransfer === "yes"}
                                onChange={() => handleAccountTransferButton("yes")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="accountTransfer1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="d-inline-flex">
                              <input
                                type="radio"
                                name="accountTransfer"
                                id="accountTransfer2"
                                className="custom-radio"
                                value="no"
                                checked={accountTransfer === "no"}
                                onChange={() => handleAccountTransferButton("no")}
                              />
                              <label
                                className="custom-radio-label"
                                htmlFor="accountTransfer2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {errors.accountTransfer && (
                            <div className="error-message">
                              {errors.accountTransfer}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    
                      <div className="basic-faq col-md-4 mt-2">
                        <label
                          htmlFor="companySelect"
                          className="form-label fw-semibold"
                        >
                          Company
                        </label>{" "}
                        <span className="star-required text-danger">*</span>
                        <div className="position-relative form__inner">
                          <Form.Select
                            className={`form-control ${errors.companySelected ? "is_invalid" : ""
                              }`}
                            id="companySelect"
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                            required
                          >
                            <option value="">Select Company</option>
                            {companiesList.map((company) => (
                              <option key={company.id} value={company.id}>
                                {company.companyName}
                              </option>
                            ))}
                          </Form.Select>
                          {errors.companySelected && (
                            <div className="error-message">
                              {errors.companySelected}
                            </div>
                          )}
                        </div>
                      </div>
                    
                    
                      <>
                        <div className="basic-faq col-md-4 mt-2">
                          <label
                            htmlFor="feeTypeSelect"
                            className="form-label fw-semibold"
                          >
                            Fee Type
                          </label>{" "}
                          <span className="star-required text-danger">*</span>
                          <div className="position-relative form__inner">
                            <Form.Select
                              className="form-control"
                              id="feeTypeSelect"
                              value={feeData.feeType}
                              onChange={handleFeeChange}
                              required
                            >
                              <option value="FLAT">Flat $</option>
                              <option value="PERCENTAGE">Percentage (%)</option>
                            </Form.Select>
                          </div>
                        </div>

                        <div className="basic-faq col-md-4 mt-2 form__inner">
                          <label
                            htmlFor="platformFeeInput"
                            className="form-label fw-semibold"
                          >
                            Fee
                            {feeData.feeType === "FLAT" && <span>($)</span>}
                            {feeData.feeType === "PERCENTAGE" && <span>(%)</span>}
                            /barrel
                          </label>{" "}
                          <span className="star-required text-danger">*</span>
                          <input
                            type="number"
                            className={`form-control ${errors.feePerBarrel ? "is_invalid" : ""
                              }`}
                            id="platformFeeInput"
                            name="platformFee"
                            value={feeData.platformFee}
                            onChange={handleInputChange}
                            placeholder="Fee/barrel"
                            min="0"
                            step="5" // Allow input of double values
                            required
                          />
                          {errors.feePerBarrel && (
                            <div className="error-message">
                              {errors.feePerBarrel}
                            </div>
                          )}
                        </div>
                      </>
                    
                  </div>
                </div>)}
              {(isValidated && !isLoading) && (<List className="warning-list">
                {validationsList?.map((validation, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start" sx={{ display: 'flex', paddingInline: '0px' }}>
                      <ListItemAvatar sx={{ minWidth: 'unset', marginTop: 0, marginRight: '2px' }}>
                        <WarningRoundedIcon sx={{ color: '#b10e1c' }} />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}
                        primary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: '#b10e1c', fontWeight: 600, wordBreak: 'break-all' }}
                            >
                              Row # {validation?.rowNumber} -
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: '#b10e1c', fontWeight: 400, marginLeft: '4px' }}
                            >
                              {validation?.errorMessages}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: '#496673', fontWeight: 400, cursor: 'pointer' }}
                            >
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    {index !== companiesList?.length - 1 && (
                      <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
                    )}
                  </React.Fragment>
                ))}
              </List>)}
              {isLoading && (
                <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', miheight: '300px' }}>
                  <Spinner animation="border" variant="secondary" className="mt-3" />
                  <Typography variant="subtitle1" sx={{ fontSize: '20px', fontWeight: 600, color: '#737376', marginTop: '12px' }}>
                    Validating file, please wait...
                  </Typography>
                </Container>
              )}
            </Modal.Body>
          </>
        )}
        {!isInvited && (
          <>
            <Modal.Footer className="justify-content-center align-items-center">
              <div className="d-flex justify-content-center position-relative w-100">
                <hr className="w-100 mt-0" />
              </div>
              <div className="">
                {!isValidated && (<button
                  className="secondary-btn px-5 mx-1"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  Cancel
                </button>)}
                {(!isValidated && !isLoading) && (<button
                  className="primary-btn px-5 mx-1"
                  onClick={sendInvite}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner size="sm"/>}
              
            Publish
                </button>)}

                {isValidated && (<button
                  className="secondary-btn px-5 mx-1"
                  onClick={() => setIsValidated(false)}
                >
                  Back
                </button>)}
              </div>
            </Modal.Footer>
          </>
        )}
        {isInvited && (
          <>
            <Modal.Footer className="justify-content-center bg-transparent">
              <div className="btn-group row d-flex justify-content-center w-100">
                <div className="col-12">
                  <button className="secondary-btn w-100" onClick={handleClose}>
                    Close
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}