import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import MessageBox from './MessageBox';
import { WebSocketProvider } from '../../Websocket/WebSocketProvider';
import { useParams } from 'react-router';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import SearchBar from '../Static/SearchBar';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import { CommonService } from '../../Service/CommonService';

interface ContainerMessageBoxProps {
  requestType: string;
  sellerCompanyId: string;
  isSeller: boolean;
}

const ContainerMessageBox: React.FC<ContainerMessageBoxProps> = ({requestType, sellerCompanyId, isSeller}) => {
  //requestId
  const { id } = useParams<{ id: string }>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const titleElement = titleRef.current;

    const handleFocusIn = (event) => {
      if (titleElement && titleElement.contains(event.target)) {
        titleElement.classList.add("search-focused");
      }
    };

    const handleFocusOut = (event) => {
      if (titleElement && titleElement.contains(event.target)) {
        titleElement.classList.remove("search-focused");
      }
    };

    // Attach event listeners to the document
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);


  const handleMsgBoxCollapse = ()=> {
    setIsCollapsed(!isCollapsed);
  }
  
  return (
    <WebSocketProvider requestId={id} sellerCompanyId={sellerCompanyId}>
      <div className={`msgbox-container ${isCollapsed ? '' : 'show'} ${
        CommonService.isUser() && 'user-msgbox-container'
      }`}>
        <div className="title d-flex" ref={titleRef}>
          <div className='pb-2 d-flex align-items-center gap-2'>
          <div className="msg-icn">
            <MarkunreadOutlinedIcon sx={{ fontSize: '36px', color: '#FFF' }} />
          </div>
          <h3 className="text mb-0">Messages</h3>
          </div>
          <div className='d-flex align-items-center'>
            {/* <div className='buyer-seller-tabbing-wrapper'>
              <div role="group" className="btn-group">
                <button type="button" aria-label="Grid" className="btn tab-btn" style={{backgroundColor:"#FFFFFF"}}><span className='position-relative text' style={{color:"#737376", fontWeight:"700"}}>Buyer <span className='msg-count position-absolute'>2</span></span></button>
                <button type="button" aria-label="Grid" className="btn tab-btn" ><span className='position-relative text'>Seller <span className='msg-count position-absolute'>2</span></span></button>
              </div>
            </div> */}
            <div className="search-box ms-auto pb-2">
              <SearchBar search={searchQuery} setSearch={setSearchQuery} />
            </div>
          </div>
          {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form> */}
        </div>
        {/* <div className="order-detail-wrapper bg-white">
          <div className='pb-2 d-flex align-items-center gap-3 justify-content-between'>
            <div className="order-number-wrapper">
              <p className='heading'>Order Number</p>
              <p className='number'>2411260026</p>
            </div>
            <div className="order-number-wrapper">
              <p className='heading'>Order Item</p>
              <p className='number'>2411260026</p>
            </div>
          </div>
        </div> */}
          {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form> */}
        
      <MessageBox selectedGroup={""} groupRequestId={id} user1="User1" user2={sellerCompanyId == null ? "User2" : sellerCompanyId} searchQuery={searchQuery} requestType = {requestType} isSeller={isSeller}/>
      </div>
    </WebSocketProvider>
  );
};

export default ContainerMessageBox;

