import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import { Route, Switch, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";
import SingleReInvite from "../popups/SingleReInvite";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import Constant from "../../Constant";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, ButtonGroup } from "react-bootstrap";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StatusDropdown from "../Common/StatusDropdown";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import Spinner from "../Common/Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";

const ActiveOrder = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewOrderCount, setReviewOrderCount] = useState(0);
  const [search, setSearch] = useState("");
  let accepted: any[] = [];
  const disableAction = useRef(false);
  const [isNeedReviewClicked, setNeedReviewClicked] = useState(false);
  const selectedRows = useRef(accepted);
  const [userRole, setUserRole] = useState<string | null>(null);
  const rejectionNote = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  /* interface StatusDetails {
     id: string;
     status: string;
   }*/
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "", quantity: 0});
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  let dashboardPrefrence="";
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const handleClose = () => {
    setShow(false);
    setNote("");
    setError("");
  };
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  const controllerRef = useRef<AbortController | null>(null);
  const [filter, setFilter] = useState<any>({
    requestNumber: null,
    requestedOn: null,
    quantity: null,
    finalAmount: null,
    companyName: null,
    firstName: null,
    email:null,
    status: null,
  });

  const handleStatusChange = async (newStatus: string, rowId: string, quantity: number) => {
    console.log(newStatus);
    // const statusMap: { [key: string]: string } = {
    //   "1": "Reserved",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      companyId: CommonService.getCompanyId(),
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      quantity: quantity
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const changeOrderStatus = async () => {

    console.log("rejection note value"+ rejectionNoteValue);

    if (rejectionNoteValue.trim().length == 0 && statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      console.log("Come inside it");
      setError("Please enter rejection note")
      return;
    }
    setIsLoading(true);
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      console.log(statusDetailsRef.current);
      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      setIsLoading(false);
      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      console.log("status updated successfully");
      handleClose();
      CommonService.Toast.fire({
        title: "Order status updated successfully",
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false)
      console.log("error occurred which api calling");
    }
  };

  const fetchPendingOrdersCount = async () => {
    try {
      const response = await OrderApiService.getPendingOrdersCount();
      setReviewOrderCount(response.data);
    } catch (error) {
      console.log("error occurred while api calling");
    }
  };

  const fetchAllAcceptedOrders = async (page : number, pageSize: number, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      // First fetch the count of pending orders
      await fetchPendingOrdersCount();
    
      const response = await OrderApiService.getOrders(page, pageSize,false,false, null, filter, signal);
      setTableData(response.data.content);
      setTotalCount(response.data.totalElements); 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  };

  //get all the pending orders
  const showPendingOrders = async (page : number, pageSize: number,tempFilter) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!isNeedReviewClicked) {
      try {
        setNeedReviewClicked(true);
        setIsLoading(true);      setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;
      
        const response = await OrderApiService.getOrders(page, pageSize,true,false, null,tempFilter, signal);
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements); 
      } catch (error) {
        console.log("error is occurred");
      } finally {
        setIsLoading(false); // Set the loading flag to false after the API call completes
      }
    } else {
      //update the status
      setNeedReviewClicked(false);
      fetchAllAcceptedOrders(page, pageSize,tempFilter);
    }
  };

  const justShowPendingOrders = async (page : number, pageSize: number, filter: any) => {
      const controller = new AbortController();
      const signal = controller.signal;
      try {
        setIsLoading(true);
        setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;

        const response = await OrderApiService.getOrders(page, pageSize,true,false, null, filter, signal);
        setTableData(response.data.content);
        setTotalCount(response.data.totalElements); 
      } catch (error) {
        console.log("error is occurred");
      } finally {
        setIsLoading(false); // Set the loading flag to false after the API call completes
      }
    
  };

  const history = useHistory();

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  };

  const isUserRole = userRole === "ADMIN";

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const clicked = (event: any) => {
    if (userRole === "ADMIN") {
      event.preventDefault();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;

    console.log(rejectionNoteValue);
  };

  const fetchUserRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("userRole");

    setUserRole(role);
    //  if (role == 'SUPER_ADMIN')
    fetchAllAcceptedOrders(page,pageSize,filter);
    // else if (role == 'USER')
    //   showPendingOrders();
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/order-detail/${params.row.id}`);
  }

  const handleBack = () => {
    history.goBack();
  }

  const openOrderRequests = async (page:number , pageSize : number, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
        setIsLoading(true);
        setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;

        if (currentPath.startsWith('/user/view-as-buyer/active-order'))
          dashboardPrefrence = 'Buyer';
        else if (currentPath.startsWith('/user/view-as-owner/active-order'))
          dashboardPrefrence = 'Owner';
        else
          dashboardPrefrence= CommonService.getDashboardPrefrence();
  
        const response: any = await OrderApiService.getCompanyWiseOrderRequestsPagination(params, false, dashboardPrefrence,page,pageSize, filter, signal); // Applies the status filter only if isFetchOpenRequests is true;
        setTableData(Object.values(response.data.content)); // Convert object to array
        setTotalCount(response.data.totalElements);
    } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set the loading flag to false after the API call completes
    }
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };
  
    // Update the filter state
    setFilter(tempFilter);

    if (currentPath === '/master/active-order'){
      if(isNeedReviewClicked)
        justShowPendingOrders(page,pageSize, tempFilter);
      else
        fetchAllAcceptedOrders(page ,pageSize, tempFilter);
    }
    else
      openOrderRequests(page,pageSize, tempFilter);
  };

  useEffect(() => {
    if (currentPath === '/master/active-order')
      fetchUserRole();
    else
      openOrderRequests(page,pageSize, filter);
  }, []);

  const getOptions = () => {

    switch (userRole) {
        case "SUPER_ADMIN":
            return (
                <>
                    <option value="PENDING_REVIEW">Pending Review</option>
                    <option value="UNDER_REVIEW">Under Review</option>
                    <option value="ACCEPTED">Accepted</option>
                    <option value="REJECTED">Rejected</option>
                    <option value="CONTRACT_SENT">Contract Sent</option>
                    <option value="INVOICE_SENT">Invoice Sent</option>
                    <option value="CONTRACT_EXECUTED">Contract Executed</option>
                    <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
                    <option value="CANCELLED">Cancelled</option>
                </>
            );
        
        default:
            return (
              <>
                  <option value="PENDING_REVIEW">Pending Review</option>
                  <option value="UNDER_REVIEW">Under Review</option>
                  <option value="CANCELLED">Cancelled</option>
              </>
          );
    }
};
  
  const columns: GridColDef[] = [
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.row.requestNumber || '-'}/>,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.requestNumber}
                    onChange={(event) => handleFilterChange(event, "requestNumber")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
    )

    },
    {
      field: "requestedOn",
      headerName: "Created At",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() && !CommonService.isSameCompany(row.companyId) ?  CommonService.formatStrictDate(dateTimeString) : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="birthdate"
                placeholder="MM/DD/YYYY"
                value={filter.requestedOn}
                onChange={(event) => handleFilterChange(event, "requestedOn")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
        </div>
    )
    },
    
    ...(CommonService.isAdmin()
      ? [
    {
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
    )
    }]
    : []),
    {
      field: "quantity",
      headerName: "Total Quantity",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={`${params.value} barrels`} />,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.quantity}
                    onChange={(event) => handleFilterChange(event, "quantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
    )
    },
    ...(CommonService.isAdmin() || ((CommonService.getDashboardPrefrence() == 'Buyer'))
      ? [
        {
          field: "finalAmount",
          headerName: "Total Amount",
          headerClassName: "table-header border-0 my-2 h-100",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
          renderHeader: (params) => (
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative">
                    <input
                        type="text"
                        className="form-control border mt-1"
                        style={{ textIndent: "24px", boxShadow: "none" }}
                        placeholder="Search"
                        value={filter.finalAmount}
                        onChange={(event) => handleFilterChange(event, "finalAmount")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    />
                    <SearchIcon
                        sx={{
                            color: "#6F6F6F",
                            position: "absolute",
                            top: "50%",
                            left: "8px",
                            transform: "translateY(-50%)",
                        }}
                    />
                </div>
            </div>
        )
        },
    {
      field: "firstName",
      headerName: "Created By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
    )
    } ,
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.email}
                    onChange={(event) => handleFilterChange(event, "email")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
    )
    },]
    : []),
    {
      field: "status",
      headerName: "Order Status",
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "status-badge",
      width: 150, // Set an initial width
      minWidth: 150, // Ensure the column does not shrink below this width
      flex: 1, // Allow it to grow if there is extra space
    
      renderCell: (params: any) => {
        const isStatusValid = params.row.hasValidStatus || false;
        const isItemRejected = params.row.hasItemRejected || false;
        return (
          <div onClick={(e) => e.stopPropagation()}>

            {/* Pending Review - User */}
            {(params.row.status === "PENDING_REVIEW" || params.row.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
              <StatusDropdown
                currentStatus={params.row.status}
                onStatusChange={(newStatus) =>
                  handleStatusChange(
                    newStatus,
                    params.row.id,
                    params.row.quantity
                  )
                }
                disableCondition={CommonService.isAdmin() || !CommonService.isSameCompany(params.row.companyId)}
                allowedLabels={["Cancelled"]}
              />
            )}

            {((params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
              <StatusDropdown
                currentStatus={params.row.status}
                onStatusChange={(newStatus) =>
                  handleStatusChange(
                    newStatus,
                    params.row.id,
                    params.row.quantity
                  )
                }
                disableCondition={CommonService.isUser() || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || (params.row.status === "UNDER_REVIEW" && isStatusValid)}
                allowedLabels={isStatusValid ? ["Under Review"] : isItemRejected ? ["Under Review", "Rejected"] : ["Under Review", "Accepted", "Rejected"]}
              />
            )}

            {/* Under Review - User (disabled) */}
            {(params.row?.status === "UNDER_REVIEW" && CommonService.isUser()) && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
                disableCondition={true}
                allowedLabels={[]}
              />
            )}

            {/* Accepted to Payment Received - Both User and Admin */}
            {["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED"].includes(params.row?.status) && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
                disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED" || params.row?.status === "REJECTED"}
                allowedLabels={["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Rejected"]}
              />
            )}

            {/* Accepted - Admin specific options */}
            {params.row?.status === "PAYMENT_RECEIVED" && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
                disableCondition={CommonService.isUser() || params.row?.status === "CANCELLED" || params.row?.status === "REJECTED"}
                allowedLabels={["Ownership Transferred", "Rejected"]}
              />
            )}

            {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
            {(params.row?.status === "OWNERSHIP_TRANSFERRED" || params.row?.status === "CANCELLED") && (
              <StatusDropdown
                currentStatus={formatStatus(params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row.quantity)}
                disableCondition={true}
                allowedLabels={[]}
              />
            )}

            {params.row.status === "REJECTED" && (
              <Tooltip
                title={`Rejection Note: ${params.row.rejectionNote}`}
                arrow
              >
                <StickyNote2Icon
                  className="note-icon cursor-pointer"
                  style={{ color: "gray" }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
      renderHeader: (params) => (
        <>
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative">
                    <Form.Select
                        style={{ cursor: "pointer",borderBottomColor:"#D1D1D1",marginTop:"4px" }}
                        value={filter.status}
                        className="form-control mb-1"
                        onChange={(event) => handleFilterChange(event, "status")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                        <option value="">Select Status</option>
                        {/* {getOptions()} */}
                        <option value="PENDING_REVIEW">Pending Review</option>
                        <option value="UNDER_REVIEW">Under Review</option>
                        <option value="ACCEPTED">Accepted</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="CONTRACT_SENT">Contract Sent</option>
                        <option value="INVOICE_SENT">Invoice Sent</option>
                        <option value="CONTRACT_EXECUTED">Contract Executed</option>
                        <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
                        <option value="CANCELLED">Cancelled</option>
                       
                    </Form.Select>
                </div>
            </div>
        </>
    )
    },
  ].filter(Boolean) as GridColDef[];

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    disableAction.current = flag;
  };

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  // const filteredRows = tableData?.filter(
  //   (row) =>
  //     CommonService.isAdmin() ?
  //       row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.email?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.firstName + " " + row.lastName).toLowerCase().includes(search?.toLowerCase())
  //     :
  //       row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.companyName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  // const tableDataWithIds = filteredRows.map((rowData, index) => ({
  //   ...rowData
  // }));

  return (
    <>
      <div className="usersList" style={{paddingBottom:CommonService.isAdmin() ? 51 : ""}}>
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">
              {/* {userRole == "ADMIN" && "My Orders"} */}
              {CommonService.isAdmin() && "Order Requests"}{" "}
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
              {userRole === "SUPER_ADMIN" && ( <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
                
                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 ${
                      isNeedReviewClicked ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showPendingOrders(page,pageSize,filter);
                    }}
                  >
                    Need Review
                    <span className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2 ${
                      isNeedReviewClicked ? "bg-secondary-subtle" : "bg-white"
                    }`}>
                      {reviewOrderCount}
                    </span>
                  </button>
                
              </div>
)}              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-2"> */}
                {/* {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn me-1">
                   
                  </button>
                )}
                {isNeedReviewClicked && !disableAction.current && (
                  <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                    
                  </button>
                )} */}
                {/* <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                  Delete
                </button> */}
                {/* <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                  {selectionModel.length}
                </span> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">

          <DataGrid
                      rows={tableData}
                      columns={columns}
                      className="data-grid"
                      initialState={{
                        pagination: {
                          paginationModel: { page: page, pageSize: pageSize },
                        },
                      }}
                      pagination
                      paginationModel={{
                        page,
                        pageSize,
                      }}
                      onPaginationModelChange={(newPaginationModel) => {
                        setPage(newPaginationModel.page);
                        setPageSize(newPaginationModel.pageSize);

                          if (currentPath === '/master/active-order'){
                            if(isNeedReviewClicked)
                              justShowPendingOrders(page,pageSize,filter);
                            else
                              fetchAllAcceptedOrders(page ,pageSize,filter);
                          }else
                            openOrderRequests(page,pageSize,filter);
                      }}
                      rowCount={totalCount}
                      paginationMode="server"
                      pageSizeOptions={[5, 10]}
                      // checkboxSelection
                      disableRowSelectionOnClick={true}
                      rowSelectionModel={selectionModel}
                      onRowSelectionModelChange={handleSelectionModelChange}
                      localeText={localeText}
                      onRowClick={(params) => handleRowClick(params)}
                      loading={isLoading}
                    />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="modal__wd modal__wd--submitSellReq">
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
        <form className="form__inner">
                    <textarea
                        required
                        className={`form-control ${error ? "is_invalid" : ""}`}
                        aria-label="With textarea"
                        value={rejectionNoteValue}
                        onChange={handleInputChange}
                        rows={8}
                        style={{resize:"none"}}
                        placeholder="Enter text here..."
                    />
                    {error && <div className="error-message">{error}</div>}
                </form>

        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOrderStatus} disabled={isLoading}>
               {isLoading && <Spinner/>} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActiveOrder;