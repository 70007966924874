import { IconButton, Skeleton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useState } from "react";
import "../Dynamic/UserList.scss";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CommonService } from "../../Service/CommonService";

const ManageRoles = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getAllRoles = async () => {
    setIsLoading(true);
    try {
      const response = await Service.getUserRoles(true);
      setTableData(response.data);
    } catch (error) {
      console.error("Error occurred while fetching roles:", error);
      // Optionally add a toast notification here
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString() || ''}>
        <span>{value?.toString() || '-'}</span>
      </Tooltip>
    );
  };

  const handleEditRole = (roleId: string) => {
    history.push(`/master/manage-permissions/${roleId}`); // Adjust the path as needed
  };

  const handleRowClick = (params:any) => {
    history.push(`/master/manage-permissions/${params.row?.id}`);
  }


  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Sr.No",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => {
        const rowIndex = tableDataWithIds.findIndex((row) => row.id === params.row.id);
        return <span>{rowIndex + 1}</span>;
      },
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={CommonService.formatRoleName(params.value)}/>,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value}/>
    },
    {
      field: "actions",
      headerName: "Action",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleEditRole(params.row.id);
          }}
          color="primary"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const handleBack = () => {
    history.goBack();
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter((row) => {
    const searchTerm = search.toLowerCase();
    const fullName = `${row.firstName || ''} ${row.lastName || ''}`.toLowerCase();
    
    return (
      row.roleName?.toLowerCase().includes(searchTerm) ||
      fullName.includes(searchTerm)
    );
  });

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData) => ({
    id: rowData.id || rowData._id, // Ensure each row has a unique id
    ...rowData,
  }));

  const localeText = {
    noRowsLabel: isLoading ? "Fetching roles..." : "No roles found",
  };

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        <IconButton
          onClick={handleBack}
          aria-label="Back"
          sx={{
            backgroundColor: "#FFF",
            mb: 1,
            "&:hover": { backgroundColor: "#FFF" },
          }}
        >
          <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
        </IconButton>
        <div className="header--title mb-2 me-2">
          <h1 className="title mb-0 ms-2">
            Manage Roles
          </h1>
        </div>

        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items row d-flex justify-content-end">
            <div className="col-auto mb-2">
              <SearchBar search={search} setSearch={setSearch} />
            </div>
          </div>
        </div>
      </div>
      
      <div className="user-table-container">
        <div className="userTable">

        {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            loading={isLoading}
            onRowClick={handleRowClick}
            pageSizeOptions={[5, 10]}
            localeText={localeText}
          /></>)}
        </div>
      </div>
    </div>
  );
};

export default ManageRoles;