import React, { useEffect, useState } from 'react'
import DownloadSimpleIcon from "../../assets/images/icons/DownloadSimple.svg";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Skeleton } from '@mui/material';
import { Form, ListGroup, Card, Container } from 'react-bootstrap';
import { CommonService } from '../../Service/CommonService';
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from 'react-router';
import { MessageApiService } from '../../Service/MessageApiService';
import { WebSocketProvider } from '../../Websocket/WebSocketProvider';
import MessageBox from './MessageBox';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
interface AdminMessageBoxProps {
  selectedGroup: any;
  requestId: string;
  requestType: string;
  unreadGroupsCount: number;
  setUnreadGroupsCount: (count: number) => void;
  isSeller: boolean;
}

const AdminMessageBox: React.FC<AdminMessageBoxProps> = ({ selectedGroup, requestId, requestType, unreadGroupsCount, setUnreadGroupsCount, isSeller }) => {  
  const [groups, setGroups] = React.useState<any>([]);
  const [selectedSellerId, setSelectedSellerId] = useState<any>(null);
  const [selectedSellerCompanyId, setSelectedSellerCompanyId] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groupSearchQuery, setGroupSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAdminMessageGroups();
  }, [requestId]);

  const getAdminMessageGroups = async () => {
    try {
      setIsLoading(true);
      const response = await MessageApiService.getAdminMessageGroups(requestId, requestType);
      // set buyers details unreadMessageCount 0
      response.data.unreadMessageCount = 0;
      setGroups(response.data);
      setSelectedSellerId("User1");
      setSelectedSellerCompanyId("User2");
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching Admin Message Groups" + error);
    }
  }

  const setGroupMessagesAsRead = (group: any) => {

    const updatedGroups = {
      ...groups, // Spread the existing `groups` object
      sellers: groups.sellers.map((g) =>
        g.sellerCompanyId === group.sellerCompanyId
          ? { ...g, sellerUnreadMessageCount: 0 } // Update matching seller
          : g // Keep other sellers unchanged
      ),
    };

    // Update state with the new groups array
    setGroups(updatedGroups);

    setUnreadGroupsCount(unreadGroupsCount > 0 ? unreadGroupsCount - 1 : unreadGroupsCount);
  };

  const filteredGroups = groupSearchQuery.trim() !== '' 
  ? groups.sellers.filter((g: any) => {
      const query = groupSearchQuery.trim().toLowerCase();
      return (
        g.sellerCompanyName?.trim().toLowerCase().includes(query) ||
        g.inventoryName?.some((name: string) =>
          name.trim().toLowerCase().includes(query)
        )
      );
    })
  : groups.sellers;

  return (
    <>
      {/* <div className="admin-message-box"> */}
        {/* <div className="admin-message-box-inner positio-relative"> */}
          <div className="row m-0 h-100">
            <div className="col-xl-5 px-0 px-lg-2">
{isLoading ? (<><Skeleton
                      animation="wave"
                      variant="rounded"
                      style={{height:"calc(100vh - 200px)"}}
                    /></>) : (<><div className="sidebar h-100">
                <div className="buyer-info-wrapper" onClick={() => {
                  setSelectedSellerId("User1");
                  setSelectedSellerCompanyId("User2");
                }}  style={{
                  backgroundColor:
                  selectedSellerCompanyId === "User2"
                      ? "#E3EBEC"
                      : "#F0F0F0",
                }}>
                  <p className="buyer-text">Buyer</p>
                  <p className="buyer-name">{groups.buyerCompanyName}</p>
                  {groups.unreadMessageCount !== 0 && (
                  <div className="msg-count-wrapper">
                    {groups.unreadMessageCount}
                  </div>
                  )}
                </div>
                <div className="seller-list">
                  <div className="seller-list-header">
                    <p className="heading"> Seller {requestType == 'ORDER_REQUEST' && '(s)'}</p>
                  </div>
                  {requestType == 'ORDER_REQUEST' && <div className="seller-list-header">
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="chat-search"
                        style={{ paddingLeft: "30px" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroupSearchQuery(e.target.value)}
                      />
                      <SearchIcon
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "5px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>}
                  <ListGroup variant="flush">
                    {filteredGroups?.map((seller, index) => (
                      <ListGroup.Item
                        key={seller.sellerId} // Add a key for optimal rendering
                        className="seller"
                        onClick={() => {
                          setSelectedSellerId(seller.sellerId);
                          setSelectedSellerCompanyId(seller.sellerCompanyId);
                          setGroupMessagesAsRead(seller);
                        }}
                      >
                        <div className="seller-inner" style={{
                        backgroundColor:
                        selectedSellerCompanyId === seller.sellerCompanyId
                            ? "white"
                            : "initial",
                      }}>
                          <p className="mb-1 seller-name">{seller.sellerCompanyName}</p>
                          <ul className="list-unstyled seller-item-list">
                            {seller.inventoryName.map((item, index) => (
                              <li key={index} className="seller-list-item">{item}</li>
                            ))}
                          </ul>
                          {seller.sellerUnreadMessageCount !== 0 && (
                          <div className="msg-count-wrapper">
                            {seller.sellerUnreadMessageCount}
                          </div>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                </div>
              </div></>) }

              
            </div>

            <div className="col-xl-7 px-0 px-lg-2">
              <div className="chat h-100 position-relative">
                <div className="chat-header">
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      className="chat-search"
                      style={{ paddingLeft: "30px" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                    />
                    <SearchIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "5px",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </div>
                <WebSocketProvider requestId={requestId} sellerCompanyId={selectedSellerCompanyId === "User2" ? null : selectedSellerCompanyId}>
                  {/* <Container className='p-0'> */}

                    <div className="message-list-card">
                      {selectedSellerCompanyId && (
                        <MessageBox
                          selectedGroup={selectedGroup}
                          groupRequestId={requestId}
                          user1={selectedSellerId}
                          user2={selectedSellerCompanyId}
                          searchQuery={searchQuery}
                          requestType={requestType}
                          isSeller={isSeller}
                        />
                      )}
                    </div>
                  {/* </Container> */}
                </WebSocketProvider>
              </div>
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
    </>
  )
}

export default AdminMessageBox;