import React, { useEffect, useMemo, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import SearchIcon from "@mui/icons-material/Search";
import "../Dynamic/GroupMessageBox.scss";
import "../../sass/responsive.scss";
import "../Dynamic/AddSupportRequest.scss";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Typography,
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { Container, Form, ListGroup } from "react-bootstrap";
import { CommonService } from "../../Service/CommonService";
import NoGroupMessage from "../Common/NoGroupMessage";
import SupportCommentBox from "./SupportCommentBox"; // Import the SupportCommentBox
import TrashIcon from "../../assets/images/icons/Trash.svg";
import NoTicketCreated from "../Common/NoTicketCreated";
import NoSupportComment from "../Common/NoSupportComment";
import FAQList from "../popups/FAQList";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useWebSocket,
  WebSocketProvider,
} from "../../Websocket/WebSocketProvider";
import { InventoryApiService } from "../../Service/InventoryApiService";
import DoneIcon from "@mui/icons-material/Done";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import Spinner from "../Common/Spinner";
import { useHistory, useParams } from "react-router";
import { Position } from "ckeditor5";
import InputMask from "react-input-mask";
import { SupportApiService } from "../../Service/SupportApiService";

const SupportRequest: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [signatureFile, setSignatureFile] = useState<File>();
  const [activeTab, setActiveTab] = useState("open");
  const [search, setSearch] = useState("");
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openRequestCount, setOpenRequestCount] = useState<number>(0);
  const [closedRequestCount, setClosedRequestCount] = useState<number>(0);
  const [supportRequests, setSupportRequests] = useState<any[]>([]);
  const [isNewTicket, setNewTicket] = useState(true);
  const [comment, setComment] = useState("");
  const [selectedCurrentUser, setSelectedCurrentUser] = useState(
    CommonService.getUserId()
  );

  const [serviceDocDetails, setServiceDocDetails] = useState([]);
  const [requestDescription, setRequestDescription] = useState("");
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyError, setSelectedCompanyError] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedInventory, setSelectedInventory] = useState("");
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceFieldLoading, setServiceFieldLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [currentCompaniesUserList, setCurrentCompaniesUserList] = useState([]);
  const [inventoriesList, setInventoriesList] = useState([]);
  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [searchedInventories, setSearchedInventories] = useState<any>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchSelectedInventory, setSearchSelectedInventory] = useState(null);

  const [barrelSearchTerm, setBarrelSearchTerm] = useState("");
  const [selectedBarrels, setSelectedBarrels] = useState([]);
  const [desiredQuantity, setDesiredQuantity] = useState("");
  const [finalSelectedBarrels, setFinalSelectedBarrels] = useState({
    inventories: [],
  });
  const history = useHistory();
  const [isSearch, setIsSearch] = useState(false);
  const [isAdminSelectService, setAdminSelectService] = useState(false);

  const [isDoneButtonClicked, setIsDoneButtonClicked] = useState(false);

  const [
    finalSelectedInventoriesWithBarrels,
    setFinalSelectedInventoriesWithBarrels,
  ] = useState([
    // Initial empty structure, or you can start with an empty array
    // { lotId: null, productName: "", barrelNumbers: [] }
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const {id} = useParams<{id: string}>();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


//   const fetchCustomerServiceRequestDocsForEditing = async () => {
//     //fetch all the docs service request

//     try {
//         const response = await SupportApiService.fetchCustomerServiceRequestDocsForEditing(id);
//         console.log(response?.data);
//         setServiceDocDetails(response?.data);
//     } catch (error) {
//         console.log("error occurred while api calling ");
//     }
// }


  //this method will call only when admin or user will edit the service request
  const fetchCustomerServiceRequestForEditing = async () => {
    try {

      //call api and get all the details of customer service request 
      const response = await SupportApiService.getCustomerServiceRequestForEditing(id);
      console.log(response?.data);


      //fetch the doc details
      // await fetchCustomerServiceRequestDocsForEditing();

      //set the service details
      setSelectedService(response?.data?.selectedServiceId);
      setSelectedServiceName(response?.data?.serviceName);

      //now call the api which load all the dynamic field for service 
      setServiceFieldLoading(true);
      const fieldResponse = await Service.getFieldByServiceType(response?.data?.selectedServiceId);

      //set dynamic fields
      setFields(fieldResponse?.data);

      //now fetch all the existing companies
      await fetchExistingCompanies();

      //fill all the details
      setSelectedCompany(response?.data?.selectedCompanyId);
      setSelectedUser(response?.data?.selectedUserId);
      setRequestDescription(response?.data?.comment);
      setIsDoneButtonClicked(true);
      setFinalSelectedInventoriesWithBarrels(response?.data?.finalSelectedInventoriesWithBarrels)
      setAdminSelectService(CommonService.isAdmin()? true : false);

      // Initialize form data with default values
      const initialData = {};
      const initialError = {};


      //set all the dynamic field data
      response?.data?.dynamicFields?.forEach((field) => {
        initialData[field.id] = (field.value == 'true' ? true : (field.value == 'false' ? false : field.value ))
      });


     

      
      setFormData(initialData);
      setErrors(initialError);
      setServiceFieldLoading(false);

       //if the service name is ownership transfer then we need to find the new companyId and call the api which fetch user of this company
       if(response?.data?.serviceName == 'Ownership Transfer') {
        fieldResponse?.data?.forEach(async (field) => {
          if(field?.fieldName == 'New Organization') {
            await fetchUsersByCompany(initialData[field?.serviceTypeServiceFieldId]);
          }
        })
      }

    } catch(error) {
      setServiceFieldLoading(false);
      console.log("error occurred while api calling ");

    }
  }

  useEffect(() => {
    // loadSupportRequests("OPEN");
    // getOpenRequestCount();
    // getClosedRequestCount();
     
    fetchAllServices();
    fetchUsersByCurrentCompany();

    if(id) {
      fetchCustomerServiceRequestForEditing(); 
    }
  }, []);

  const fetchAllServices = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getAllServiceType();
      setServices(response.data);
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFieldsByServiceType = async (serviceTypeId) => {
    try {
      setServiceFieldLoading(true);
      const response = await Service.getFieldByServiceType(serviceTypeId);
      setFields(response.data);

      // Initialize form data with default values
      const initialData = {};
      const initialError = {};
      response.data.forEach((field) => {
        if (
          field.dataType === "current_company" ||
          field.dataType === "current_user"
        ) {
          initialData[field.serviceTypeServiceFieldId] =
            field.dataType === "current_company"
              ? CommonService.getCompanyId()
              : field.dataType === "current_user"
              ? CommonService.getUserId()
              : "";
        }
      });
      setSelectedCompany(CommonService.isUser() ? CommonService.getCompanyId() : '')
      setFormData(initialData);
      setErrors(initialError);
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setServiceFieldLoading(false);
    }
  };

  const fetchExistingCompanies = async () => {
    try {
      const response = await Service.fetchCompaniesList();
      const companyId = CommonService.getCompanyId();

      //exclude this company from the list
      const existingCompanies = response.data.filter(
        (company) => company.id !== companyId
      );
      setCompaniesList(existingCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  // const fetchInventoriesByCompany = async (companyId: any) => {
  //   try {
  //     const response = await InventoryApiService.getInventoryListByCompanyId(
  //       companyId
  //     );
  //     setInventoriesList(response.data);
  //   } catch (error) {
  //     console.error("Error while calling api:", error);
  //   }
  // };

  const fetchUsersByCompany = async (companyId: any) => {
    try {
      const response = await Service.fetchUsersList(companyId);
      setUsersList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchUsersByCurrentCompany = async () => {
    try {
      const response = await Service.fetchUsersList(
        CommonService.getCompanyId()
      );
      setCurrentCompaniesUserList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleServiceChange = (serviceId, serviceName) => {
  
    setSelectedServiceName(serviceName);
    setSelectedService(serviceId);
    if (serviceId) {
      setFormData((formData) => ({ ...formData, serviceId: serviceId }));
      setRequestDescription("");
      setFiles([]);
      setSelectedCompanyError("");
      setFinalSelectedInventoriesWithBarrels([]);
      setSearchTerm("");
      setBarrelSearchTerm("");
      setAdminSelectService(true);
      fetchFieldsByServiceType(serviceId);
      //set this serviceId into the formData
      if (serviceId) {
        setFormData((formData) => ({ ...formData, serviceId: serviceId }));
      }
      fetchExistingCompanies();
    } else {
      setFields([]);
      setFormData({});
    }
  };

  const handleCompanyChange = (value: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    const companyId = value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));

    fields.forEach((field) => {
      if (
        field.fieldName == "New User"
      ) {
        formData[field.serviceTypeServiceFieldId] = ''
      }
    });

    setUsersList([]);

    if(companyId) {
       fetchUsersByCompany(companyId);
    }
  };

  const handleUserCompanyChange = (value: any) => {
    setSelectedCompanyError("");
    const companyId = value;

    //if admin change the company reset the all the inventory details
    setSearchedInventories([]);
    setSearchSelectedInventory(null);
    setFinalSelectedInventoriesWithBarrels([]);

    if(companyId) {
      setSelectedCompany(companyId);
    }
  };

  const handleUserChange = (value: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    const userId = value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));
  };

  const handleCurrentUserChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    // const userId = value;
    // setSelectedCurrentUser(userId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));
  };

  const handleRequestDescriptionChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setRequestDescription(value);
  };

  const handleInventoryChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const inventoryId = value;
    setSelectedInventory(inventoryId);
  };

  const handleFieldChange = (value, serviceTypeServiceFieldId) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    let processedValue = value;

    // Convert "yes"/"no" to true/false
    if (value === "yes") {
      processedValue = true;
    } else if (value === "no") {
      processedValue = false;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: processedValue,
    }));
  };

  const isValidate = () => {
    let isValid = true;

    const newErrors: Record<string, string> = {};

    fields.forEach((field) => {
      if (field.mandatory) {
        //first check if the data type is add_inventory or price
        if (field.dataType === "add_inventory" || field.dataType === "price" || errors[field.serviceTypeServiceFieldId]) {
          if (
            (field.dataType === "add_inventory" &&
              finalSelectedInventoriesWithBarrels?.length <= 0) ||
            (field.dataType === "price" && price <= 0) 
          ) {
            newErrors[field.serviceTypeServiceFieldId] = "error";
            isValid = false;
          }
        
          else if(errors[field.serviceTypeServiceFieldId]) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId];
            isValid = false;
          }
        
        }

        //now check for radio buttons
        else if (field.dataType === "radio") {
          // Check if the value is actually undefined
          if (
            typeof formData[field.serviceTypeServiceFieldId] === "undefined"
          ) {
            newErrors[field.serviceTypeServiceFieldId] = "error";
            isValid = false;
          }
        }

        else if(field.dataType == "contact_number") {
          if(formData[field.serviceTypeServiceFieldId]?.trim()?.length != 10) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId] ? errors[field.serviceTypeServiceFieldId]: "This field is required";
            isValid = false; 
          }
        }

        else if(field.dataType == "email") {
          if(!validateEmail(formData[field.serviceTypeServiceFieldId])) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId] ? errors[field.serviceTypeServiceFieldId] : "This field is required";
            isValid = false; 
          }
        }

        // check if for other dynamic fields
        else if (!formData[field.serviceTypeServiceFieldId]) {
          newErrors[field.serviceTypeServiceFieldId] = "error";
          isValid = false;
        }
      }
    });

    if(CommonService.isAdmin() && !selectedCompany) {
      setSelectedCompanyError("Please select company");
      isValid = false;
    }
    setErrors(newErrors);
    console.log(isValid);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Transform formData into a list of objects with id and value
    const dynamicFields = Object.keys(formData).map((key) => ({
      id: key,
      value: formData[key],
    }));

    if (await isValidate()) {
      // Prepare the object to send
      const requestData = {
        dynamicFields,
        selectedCompanyId: selectedCompany,
        selectedUserId: CommonService.getUserId(),
        selectedInventoryId: selectedInventory,
        selectedServiceId: selectedService,
        customerServiceRequestId : id ? id : null,
        comment: requestDescription,
        price: price,
        quantity: quantity,
        finalSelectedInventoriesWithBarrels,
      };

      const serviceRequestData = new FormData();
      if (files) {
        Array.from(files).forEach((file) => {
          serviceRequestData.append("file[]", file);
        });
      }

      serviceRequestData.append(
        "param",
        new Blob([JSON.stringify(requestData)], {
          type: "application/json",
        })
      );

      // Log the form data to the console
      console.log("Form data to submit:", requestData);
      setIsLoading(true);

      // call the api
      try {
        const response = await Service.saveCustomerSupportRequest(
          serviceRequestData
        );
        setIsLoading(false);
        CommonService.Toast.fire({
          title: `Request ${id ? "Updated" : "Submitted"} Successfully`,
          icon: "success",
        });
        const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
        history.push(`${linkPrefix}/customer-service`);
      } catch (error) {
        setIsLoading(false);
        console.log("error occurred while api calling " + error);
      }
    }
  };

  const handleQuantityChange = (
    quantity: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const value = Number(quantity);
    setQuantity(value);
  };

  const handlePriceChange = (price: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const value = Number(price);
    setPrice(value);
  };

  const handleNumberOnBlur = (value: any, serviceTypeServiceFieldId: any) => {
    console.log(
      "this is my value  " + value + " and id " + serviceTypeServiceFieldId
    );
    const numValue = Number(value);
    if (numValue <= 0 || !value) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleFieldBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleEmailBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "This field is required" }));
    }

    else if(!validateEmail(value?.trim())) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "Please enter valid email" }));
    }
  }

  const handleContactBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "This field is required" }));
    }

    else if(value?.trim()?.length != 10) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "Please enter valid contact number" }));
    }
  }

  const handleCompanyBlur = (serviceTypeServiceFieldId: any) => {
    if (!formData[serviceTypeServiceFieldId]) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleInventoryBlur = (serviceTypeServiceFieldId: any) => {
    if (!selectedInventory) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleUserBlur = (serviceTypeServiceFieldId: any) => {
    if (!formData[serviceTypeServiceFieldId]) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleSearchInventory = async (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    // handleFieldChange(value,serviceTypeServiceFieldId);
    setSearchSelectedInventory(null);
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setSearchTerm(value);
    console.log(value)

     if(!selectedCompany) {
      setSelectedCompanyError("Please select company");
      setSearchedInventories([]);
      setSearchSelectedInventory(null);
      return ;
     } 
    //for searching required minimum 4 characters
    if (value?.trim().length > 2) {
      setIsSearch(true);
      //now call the api which fetched matching inventories
      try {
        const response = await InventoryApiService.searchInventory(value?.trim(), selectedCompany);
        setSearchedInventories(response?.data);
        setSearchSelectedInventory(response?.data[0]);
        setIsSearch(false);
      } catch (error) {
        console.log("error while calling the api");
        setIsSearch(false);
      }
    } else {
      setSearchedInventories([]);
      setSearchSelectedInventory(null);
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Filter barrel numbers based on search term
  const filteredBarrelNumbers = searchSelectedInventory
    ? searchSelectedInventory.barrelNumbers.filter((barrel) =>
        barrel?.barrelNumber?.toLowerCase()
          .includes(barrelSearchTerm?.toLowerCase())
      )
    : [];

  // Handle select all barrels checkbox
  const handleSelectAllBarrels = () => {
    const { lotNo, title } = searchSelectedInventory;

    // Check if all barrels are currently selected
    const isAllSelected =
      selectedBarrels.length === filteredBarrelNumbers.length;

    if (isAllSelected) {
      // Deselect all barrels
      setSelectedBarrels([]);

      // Update finalSelectedInventoriesWithBarrels by removing the current lot
      setFinalSelectedInventoriesWithBarrels((prev) =>
        prev.filter((inventory) => inventory.lotId !== lotNo)
      );
    } else {
      // Select all filtered barrel numbers
      const selectedBarrelsForCurrentLot = filteredBarrelNumbers.map(
        (barrel) => ({
          barrelNumber: barrel.barrelNumber,
          inventoryId: barrel.inventoryId,
        })
      );

      setSelectedBarrels([...filteredBarrelNumbers]);

      // Update finalSelectedInventoriesWithBarrels
      setFinalSelectedInventoriesWithBarrels((prev) => {
        // Remove any previous selection of this lot
        const updatedInventories = prev.filter(
          (inventory) => inventory.lotId !== lotNo
        );

        // Add the updated selection for this lot
        return [
          ...updatedInventories,
          {
            lotId: lotNo,
            productName: title,
            barrelNumbers: selectedBarrelsForCurrentLot,
          },
        ];
      });
    }
  };

  const handleRandomSelection = (value: any) => {
    setDesiredQuantity(value);
    if (value?.trim().length <= 0) {
      return;
    }
    const quantity = parseInt(value);
    const { lotNo, title } = searchSelectedInventory;

    if (quantity > 0 && quantity <= filteredBarrelNumbers.length) {
      // Shuffle the filtered barrel numbers
      const shuffled = [...filteredBarrelNumbers].sort(
        () => 0.5 - Math.random()
      );

      // Take the first 'quantity' barrels
      const randomBarrels = shuffled.slice(0, quantity).map((barrel) => ({
        barrelNumber: barrel.barrelNumber,
        inventoryId: barrel.inventoryId,
      }));

      // Update selected barrels for current UI
      setSelectedBarrels(randomBarrels);

      // Update finalSelectedInventoriesWithBarrels
      setFinalSelectedInventoriesWithBarrels((prev) => {
        // Remove any previous selection of this lot
        const updatedInventories = prev.filter(
          (inventory) => inventory.lotId !== lotNo
        );

        // Add the updated selection for this lot
        return [
          ...updatedInventories,
          {
            lotId: lotNo,
            productName: title,
            barrelNumbers: randomBarrels,
          },
        ];
      });
    } else {
      CommonService.Toast.fire({
        title: `Please enter a number between 1 and ${filteredBarrelNumbers.length}`,
        icon: "error",
      });
    }
  };

  // const handleSelectBarrel = (barrelNumber) => {
  //   if (!searchSelectedInventory) {
  //     CommonService.Toast.fire({
  //       title: "Please select an inventory first",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   const { lotNo, title } = searchSelectedInventory;

  //   // Find the selected inventory in the final selected inventories
  //   const existingInventoryIndex =
  //     finalSelectedInventoriesWithBarrels.findIndex(
  //       (inv) => inv.lotId === lotNo
  //     );

  //   // Find the inventoryId for the selected barrel number
  //   const inventoryId =
  //     filteredBarrelNumbers.find(
  //       (barrel) => barrel.barrelNumber === barrelNumber
  //     )?.inventoryId || "";

  //   if (existingInventoryIndex !== -1) {
  //     // Inventory already exists, update the selected barrels
  //     setFinalSelectedInventoriesWithBarrels((prev) => {
  //       const updatedInventories = [...prev];
  //       const existingInventory = updatedInventories[existingInventoryIndex];

  //       // Check if the barrel is already selected
  //       const isBarrelSelected = existingInventory.barrelNumbers.some(
  //         (barrel) => barrel.barrelNumber === barrelNumber
  //       );

  //       if (isBarrelSelected) {
  //         // Barrel is already selected, remove it
  //         existingInventory.barrelNumbers =
  //           existingInventory.barrelNumbers.filter(
  //             (barrel) => barrel.barrelNumber !== barrelNumber
  //           );
  //       } else {
  //         // Barrel is not selected, add it
  //         existingInventory.barrelNumbers.push({ barrelNumber, inventoryId });
  //       }

  //       return updatedInventories;
  //     });
  //   } else {
  //     // Inventory does not exist, create a new one
  //     const newInventorySelection = {
  //       lotId: lotNo,
  //       productName: title,
  //       barrelNumbers: [{ barrelNumber, inventoryId }],
  //     };

  //     setFinalSelectedInventoriesWithBarrels((prev) => [
  //       ...prev,
  //       newInventorySelection,
  //     ]);
  //   }
  // };

  const handleSelectBarrel = (barrelNumber) => {
    // Check if an inventory is selected
    if (!searchSelectedInventory) {
      CommonService.Toast.fire({
        title: "Please select an inventory first",
        icon: "error",
      });
      return;
    }
  
    const { lotNo, title } = searchSelectedInventory;
  
    // Find the selected inventory in the final selected inventories
    const existingInventoryIndex = finalSelectedInventoriesWithBarrels.findIndex(
      (inv) => inv.lotId === lotNo
    );
  
    // Find the inventoryId for the selected barrel number
    const inventoryId =
      filteredBarrelNumbers.find(
        (barrel) => barrel.barrelNumber === barrelNumber
      )?.inventoryId || "";
  
    if (existingInventoryIndex !== -1) {
      // Inventory already exists, update the selected barrels
      setFinalSelectedInventoriesWithBarrels((prev) => {
        const updatedInventories = [...prev];
        const existingInventory = updatedInventories[existingInventoryIndex];
  
        // Check if the barrel is already selected
        const isBarrelSelected = existingInventory.barrelNumbers.some(
          (barrel) => barrel.barrelNumber === barrelNumber
        );
  
        if (isBarrelSelected) {
          // Barrel is already selected, remove it
          existingInventory.barrelNumbers = existingInventory.barrelNumbers.filter(
            (barrel) => barrel.barrelNumber !== barrelNumber
          );
  
          // If no barrels are left after removal, remove the entire inventory entry
          if (existingInventory.barrelNumbers.length === 0) {
            // Remove the entire inventory entry from the array
            updatedInventories.splice(existingInventoryIndex, 1);
          }
        } else {
          // Barrel is not selected, add it
          existingInventory.barrelNumbers.push({ barrelNumber, inventoryId });
        }
  
        return updatedInventories;
      });
    } else {
      // Inventory does not exist, create a new one
      const newInventorySelection = {
        lotId: lotNo,
        productName: title,
        barrelNumbers: [{ barrelNumber, inventoryId }],
      };
  
      setFinalSelectedInventoriesWithBarrels((prev) => [
        ...prev,
        newInventorySelection,
      ]);
    }
  };

  // Handle Done button click to add to final selection
  const handleDoneSelection = () => {
    // Reset states
    setSearchSelectedInventory(null);
    setSearchedInventories([]);
    setSelectedBarrels([]);
    setSearchTerm("");
    setBarrelSearchTerm("");
    setDesiredQuantity("");
    setIsDoneButtonClicked(true);
  };

  // Remove an inventory from the final selection
  const handleRemoveInventory = (lotNo) => {
    const updatedFinalSelectedInventoriesWithBarrels =
      finalSelectedInventoriesWithBarrels.filter((inv) => inv.lotId !== lotNo);
    setFinalSelectedInventoriesWithBarrels(
      updatedFinalSelectedInventoriesWithBarrels
    );
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleDeleteDoc = async (id:any) => {
    //call the api and delete this doc
    try {
    const response = await SupportApiService.deleteCustomerServiceRequestDoc(id);
    console.log(response.data);
    // fetchCustomerServiceRequestDocsForEditing();
    } catch (error) {
      console.log("error occurred while api calling" + error);
    }
  }

  const handleDeleteSignatureFile = () => {
    setSignatureFile(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Replace old files with new ones
      const newFiles = Array.from(event.target.files);
      setFiles(newFiles);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const handleSignatureFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      // Replace old files with new ones
      setSignatureFile(event.target.files[0]);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const highlightMatchedForBarrels = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
  
    const lowerText = text.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();
    const matchIndex = lowerText.indexOf(lowerSearchTerm);
  
    if (matchIndex === -1) return text;
  
    return (
      <>
        {text.slice(0, matchIndex)}
        <strong style={{fontWeight:"900"}}>
          {text.slice(matchIndex, matchIndex + searchTerm.length)}
        </strong>
        {text.slice(matchIndex + searchTerm.length)}
      </>
    );
  };

  
  const renderField = (field) => {
    switch (field.dataType) {
      case "company_dropdown":
        return (
          <div className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6 col-xl-3"
          }`}>
           
            <div className="form__field--wrapper">
              <label htmlFor="service-dropdown" className="form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleCompanyChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  onBlur={() =>
                    handleCompanyBlur(field.serviceTypeServiceFieldId)
                  }
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Company</option>
                  {companiesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>

              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a company</span>
              )}
            </div>
          </div>
        );
      case "user_dropdown":
        return (
          <div className="col-sm-6 col-xl-3">
            <div className="mb-6 form__field--wrapper">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleUserChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  onBlur={() => handleUserBlur(field.serviceTypeServiceFieldId)}
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                >
                  <option value="">Select User</option>
                  {usersList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.userName}
                    </option>
                  ))}
                </select>
              </div>

              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a user </span>
              )}
            </div>
          </div>
        );
      case "add_inventory":
        return (
          <div className="col-12">
            <div key={field.fieldName} className="form__field--wrapper">
              {/* Field Label */}
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="position-relative">
                {/* Search Input */}
                <SearchIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "15px",
                    transform: "translateY(-50%)",
                  }}
                />
                <input
                  type="text"
                  value={searchTerm}
                  placeholder="Search by Inventory Title, Lot ID or Barrel number"
                  onChange={(e) =>
                    handleSearchInventory(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  className={`form-control service-req-input add-inventory ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                />
                {isSearch && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "15px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Spinner />
                  </div>
                )}

                {searchedInventories.length > 0 && (
                  <div className="position-absolute top-100 left-0 right-0 bottom-0 w-100">
                    <div className="serched-inventory-modal row m-0 w-100 bg-white">
                      <div
                        className={`px-0 ${
                          !searchSelectedInventory ? "col-lg-12" : "col-lg-3"
                        }`}
                      >
                        <div className="list-wrapper">
                          {searchedInventories.map((inventory, index) => (
                            <div
                              key={index}
                              className={`list-item-wrapper ${
                                selectedInventory === inventory ? "" : ""
                              }`}
                              onClick={() =>
                                setSearchSelectedInventory(inventory)
                              }
                              style={{
                                backgroundColor:
                                  searchSelectedInventory != inventory
                                    ? "#f0f0f0"
                                    : "#fff",
                              }}
                            >
                              <p className="list-inventory-title">
                              {highlightMatchedForBarrels(inventory.title, searchTerm)}{" "}
          <span className="list-lot-no">
            ({highlightMatchedForBarrels(inventory.lotNo, searchTerm)})
          </span>
                              </p>
                              {finalSelectedInventoriesWithBarrels.find(
                                (inv) =>
                                  inv.lotId === inventory.lotNo &&
                                  inv.barrelNumbers.length > 0
                              )?.barrelNumbers.length > 0 && (
                                <span className="barrel-count" style={{
                                  backgroundColor:
                                    searchSelectedInventory != inventory
                                      ? "#fff"
                                      : "#f0f0f0",
                                }}>
                                  {
                                    finalSelectedInventoriesWithBarrels.find(
                                      (inv) =>
                                        inv.lotId === inventory.lotNo &&
                                        inv.barrelNumbers.length > 0
                                    )?.barrelNumbers.length
                                  }
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {searchSelectedInventory && (
                        <div className="col-lg-9 px-0">
                          {/* Barrel Numbers Display */}

                          <div className="searched-inventory-modal--right">
                            <div className="right-header d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                              {/* <div> */}
                              {/* Barrel Search and Random Selection */}
                              <div className="search-input-wrapper position-relative">
                                {/* Search Input */}
                                <SearchIcon
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "15px",
                                    transform: "translateY(-50%)",
                                  }}
                                />
                                <input
                                  type="text"
                                  placeholder="Search barrel numbers"
                                  value={barrelSearchTerm}
                                  onChange={(e) =>
                                    setBarrelSearchTerm(e.target.value)
                                  }
                                  className="form-control flex-grow"
                                />
                              </div>
                              <div className="d-flex align-items-center gap-4 ms-auto">
                                {/* Select All Checkbox */}
                                <div className="header-field-wrapper">
                                  <input
                                    type="checkbox"
                                    id="select-all-barrels"
                                    checked={
                                      finalSelectedInventoriesWithBarrels.find(
                                        (inv) =>
                                          inv.lotId ===
                                          searchSelectedInventory.lotNo
                                      )?.barrelNumbers.length ===
                                      filteredBarrelNumbers.length
                                    }
                                    onChange={handleSelectAllBarrels}
                                    className=""
                                  />
                                  {/* <label htmlFor="select-all-barrels" className="text-sm">
                      Select All ({filteredBarrelNumbers.length} barrels)
                    </label> */}
                                  <label
                                    htmlFor="select-all-barrels"
                                    className="header-field-label"
                                  >
                                    Select All
                                  </label>
                                </div>
                                <div className="header-field-wrapper d-flex align-items-center gap-2">
                                  <label className="header-field-label text-nowrap">
                                    Selected Quantity:
                                  </label>
                                  <div style={{ width: "70px" }}>
                                    <input
                                      type="number"
                                      // placeholder="Qty"
                                      value={desiredQuantity}
                                      onChange={(e) =>
                                        handleRandomSelection(e.target.value)
                                      }
                                      className="form-control"
                                      min="1"
                                    />
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>

                            {/* Barrel Numbers List */}
                            <div className="barrel-list-wrapper ">
                              <div className="row m-0 g-2">
                                {filteredBarrelNumbers.map((barrel, index) => (
                                  <div
                                    key={index}
                                    className={`inventery-barrel-wrapper col-sm-6 col-lg-4 col-xl-3 ${
                                      finalSelectedInventoriesWithBarrels
                                        .find(
                                          (inv) =>
                                            inv.lotId ===
                                            searchSelectedInventory.lotNo
                                        )
                                        ?.barrelNumbers.some(
                                          (selectedBarrel) =>
                                            selectedBarrel.barrelNumber ===
                                            barrel.barrelNumber
                                        )
                                        ? "bg-green-100 border-green-300"
                                        : "bg-gray-50 hover:bg-gray-100"
                                    }`}
                                  >
                                    {/* <div className="d-flex align-items-center gap-2 "> */}

                                    <span
                                      onClick={() =>
                                        handleSelectBarrel(barrel.barrelNumber)
                                      }
                                      className="barrel-number--inner d-flex align-items-center gap-1"
                                      style={{
                                        backgroundColor:
                                          finalSelectedInventoriesWithBarrels
                                            .find(
                                              (inv) =>
                                                inv.lotId ===
                                                searchSelectedInventory.lotNo
                                            )
                                            ?.barrelNumbers.some(
                                              (selectedBarrel) =>
                                                selectedBarrel.barrelNumber ===
                                                barrel.barrelNumber
                                            )
                                            ? "transparent"
                                            : "#F7F7F7",
                                        borderColor:
                                          finalSelectedInventoriesWithBarrels
                                            .find(
                                              (inv) =>
                                                inv.lotId ===
                                                searchSelectedInventory.lotNo
                                            )
                                            ?.barrelNumbers.some(
                                              (selectedBarrel) =>
                                                selectedBarrel.barrelNumber ===
                                                barrel.barrelNumber
                                            )
                                            ? "#8F9195"
                                            : "transparent",
                                      }}
                                    >
                                      {finalSelectedInventoriesWithBarrels
                                        .find(
                                          (inv) =>
                                            inv.lotId ===
                                            searchSelectedInventory.lotNo
                                        )
                                        ?.barrelNumbers.some(
                                          (selectedBarrel) =>
                                            selectedBarrel.barrelNumber ===
                                            barrel.barrelNumber
                                        ) ? (
                                        <DoneIcon />
                                      ) : (
                                        <AddSharpIcon sx={{ mr: 0.5 }} />
                                      )}
                                      <span className="barrel-number">
                                      {highlightMatchedForBarrels(barrel.barrelNumber, barrelSearchTerm)}
                                      </span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="modal-footer-wrapper">
                              <button
                                onClick={handleDoneSelection}
                                className="done-btn"
                                // disabled={
                                //   !finalSelectedInventoriesWithBarrels.find(
                                //     (inv) =>
                                //       inv.lotId ===
                                //       searchSelectedInventory.lotNo
                                //   )?.barrelNumbers.length
                                // }
                              >
                                <DoneIcon className="me-1" />
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Final Selected Barrels Display */}
              {isDoneButtonClicked &&
                finalSelectedInventoriesWithBarrels.length > 0 && (
                  <div className="selected-inventory-detail-wrapper">
                    {/* <div className="header-wrapper d-flex align-items-center">
                  <h3 className="detail-heading">
                    Selected Inventory Details
                  </h3>
                  <div className="d-flex align-items-center">
                    <p className="total-quantity">Quantity: <span>80</span></p>
                    <button className="remove-btn">Remove</button>
                  </div>
                  </div> */}
                    {finalSelectedInventoriesWithBarrels.map(
                      (inventory, index) => (
                        <div key={index} className="selected-inventory-detail-inner">
                          {/* <div className="font-semibold text-gray-800">
                          Lot No: {inventory.lotId}
                        </div> */}
                          <div className="header-wrapper d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                            <div className="detail-heading">
                              {inventory.productName}{" "}
                              <span className="font-semibold text-gray-800">
                                ({inventory.lotId})
                              </span>
                            </div>
                            <div className="d-flex align-items-center gap-2 ms-auto">
                              <div className="total-quantity">
                                Quantity:
                                <span> {inventory.barrelNumbers.length}</span>
                              </div>
                              <span
                                className="remove-btn"
                                onClick={() =>
                                  handleRemoveInventory(inventory.lotId)
                                }
                              >
                                <RemoveIcon className="me-1" /> Remove
                              </span>
                            </div>
                          </div>
                          <div className="total-barrel-list-wrapper">
                            <div className="bareels--list-wrapper">
                              <div className="row m-0 g-2">
                                {inventory.barrelNumbers.map(
                                  (barrel, barrelIndex) => (
                                    <div
                                      key={barrelIndex}
                                      className="col-sm-6 col-lg-4 col-xl-3"
                                    >
                                      <div className="barrel-item-wrapper d-flex align-items-center gap-1 w-100">
                                        <DoneIcon />
                                        <span>{barrel.barrelNumber}</span>
                                        {/* <span className=" ">
                                  (ID: {barrel.inventoryId})
                                </span> */}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message">This field is required</span>
              )}

              {/* Searched Inventories Display */}

              {/* Barrel Numbers Display */}

              {/* Final Selected Barrels Display */}
            </div>
          </div>
        );
      case "product_dropdown":
        return (
          <div className="mb-6 form__field--wrapper">
            <label htmlFor="service-dropdown" className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <select
              id="company-dropdown"
              value={selectedInventory}
              onChange={(e) =>
                handleInventoryChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={() =>
                handleInventoryBlur(field.serviceTypeServiceFieldId)
              }
              className={`select-dropdown ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            >
              <option value="">Select Product</option>
              {inventoriesList.map((inventory) => (
                <option
                  key={inventory.inventoryId}
                  value={inventory.inventoryId}
                >
                  {inventory?.inventoryName}
                </option>
              ))}
            </select>
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">Please select a product</span>
            )}
          </div>
        );
      case "price":
        return (
          <div key={field.fieldName} className="col-sm-6 col-xl-3">
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={price}
              onChange={(e) =>
                handlePriceChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleNumberOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />

            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
      case "quantity":
        return (
          <div key={field.fieldName} className="mb-4 form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={quantity}
              onChange={(e) =>
                handleQuantityChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleNumberOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
        );
      case "number":
        return (
          <div key={field.fieldName} className="mb-4 form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={formData[field.serviceTypeServiceFieldId] || ""}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleNumberOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
        );
      case "radio":
        return (
          <div key={field.fieldName} className="col-sm-6 col-xl-4">
            <div className="form__field--wrapper"> 
              <div className="d-flex flex-wrap gap-3 my-3">
            <label className="block form-label mb-0">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>

            <div className="d-flex align-items-center">
              <div className="d-inline-flex">
                <input
                  type="radio"
                  name={field.fieldName}
                  id={`${field.fieldName}-no`}
                  className="custom-radio service-req-input"
                  value="no"
                  checked={formData[field.serviceTypeServiceFieldId] === false}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                />
                <label
                  className="custom-radio-label"
                  htmlFor={`${field.fieldName}-no`}
                >
                  No
                </label>
              </div>
              <div className="d-inline-flex ms-2 me-2">
                <input
                  type="radio"
                  name={field.fieldName}
                  id={`${field.fieldName}-yes`}
                  className="custom-radio service-req-input"
                  value="yes"
                  checked={formData[field.serviceTypeServiceFieldId] === true}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                />
                <label
                  className="custom-radio-label"
                  htmlFor={`${field.fieldName}-yes`}
                >
                  Yes
                </label>
              </div>
            </div>
          </div>
          {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
          </div>
          </div>
        );
      case "text":
        return (
          <div key={field.fieldName}  className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6"
          }`}>
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="text"
              placeholder={field.fieldName}
              value={formData[field.serviceTypeServiceFieldId]}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
      case "comment":
        return (
          <div key={field.fieldName} className={` ${
            (selectedServiceName === "Ownership Transfer" || selectedServiceName === "Tasting" || selectedServiceName === "Private Sale")
              ? (selectedServiceName === "Private Sale" ? "col-sm-12 col-xl-9" : "col-sm-12 col-xl-6")
              : ((selectedServiceName === "Bottling" || selectedServiceName === "Blending") ? "col-md-8 col-lg-9 col-xl-12" :(selectedServiceName === "Ship Barrels" ? "col-md-8 col-lg-9 col-xl-4" : (selectedServiceName === "Dumping" ? "col-md-12 col-xl-9" : "col-md-8 col-lg-9"))
        )}`} >
            <div className="form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <textarea
                value={requestDescription}
                placeholder="Enter Text"
                onChange={(e) =>
                  handleRequestDescriptionChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                rows={3}
                style={{ resize: "none" }}
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
        );
      case "textarea":
        return (
          <div key={field.fieldName} className="col-sm-6 col-md-4">
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="textarea"
              value={formData[field.serviceTypeServiceFieldId]}
              placeholder={field.fieldName}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
      case "date":
        return (
          <div key={field.fieldName} className="col-sm-6 col-xl-3">
            <div className=" form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <input
                type="date"
                value={formData[field.serviceTypeServiceFieldId]}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                onBlur={(e) =>
                  handleFieldBlur(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                name="date"
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
        );
      case "upload_signature":
      case "upload_doc":
        return (
          <>
           <div className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4 col-lg-4"
              : "col-sm-6 col-md-4 col-lg-3"
          }`}>
            <div className="d-flex align-items-center h-100">
            <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                <IconButton
                  type="button"
                  aria-label="submit"
                  sx={{
                    transform: "rotate(45deg)",
                    padding: "8px",
                  }}
                  onClick={handleButtonClick}
                >
                  <AttachFileIcon />
                </IconButton>
                <span className="cursor-pointer" onClick={handleButtonClick}>
                {field.fieldName}
                </span>
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  {files?.map((file, index) => (
                    <div
                      className="file__upload--info d-flex align-items-center mb-1 me-2"
                      key={index}
                    >
                      <div className="file-name me-3">{file.name}</div>
                      <div className="file-delete">
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          onClick={() => handleDeleteFile(index)}
                        />
                      </div>
                    </div>
                  ))}

                  {/* if user or admin edit the service request then show these file which is already uploaded */}
                  {id && (serviceDocDetails?.map((serviceDoc, index) => (
                    <div
                      className="file__upload--info d-flex align-items-center mb-1 me-2"
                      key={index}
                    >
                      <div className="file-name me-3">{serviceDoc?.fileName.split('_').slice(1).join('_')}</div>
                      <div className="file-delete">
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          onClick={() => handleDeleteDoc(serviceDoc?.id)}
                        />
                      </div>
                    </div>)
                  ))}
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              </div>
            </div>
          </>
        );
      case "current_user":
        return (
          <div className="col-sm-6 col-xl-3">
            <div className="form__field--wrapper">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleCurrentUserChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  // onBlur = {() => handleUserBlur(field.serviceTypeServiceFieldId)}
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                >
                  {currentCompaniesUserList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.userName}
                    </option>
                  ))}
                </select>
              </div>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a user </span>
              )}
            </div>
          </div>
        );
      case "current_company":
        return (
          <div key={field.fieldName} className="col-sm-6 col-xl-3">
            <div className="form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <input
                type="text"
                disabled ={true}
                value={CommonService.getCompanyName()}
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
              />
            </div>
          </div>
        );
      case "size_dropdown":
        return (
          <div key={field.fieldName} className="col-6">
          <div className="form__field--wrapper">
          <label htmlFor="size" className="form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <div className="col">
              <select
                id="size"
                className={`form-control ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                value={formData[field.serviceTypeServiceFieldId] || ""}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
              >
                <option value="">Select Size</option>
                <option value="750mL">750mL</option>
                <option value="200mL">200mL</option>
                <option value="100mL">100mL</option>
              </select>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
          </div>
        );
      case "purpose_dropdown":
        return (
          <div key={field.fieldName} className="col-6">
          <div className="form__field--wrapper">
          <label htmlFor="size" className="mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <div className="col">
              <select
                id="purpose"
                style={{ cursor: "pointer" }}
                className={`form-control ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                value={formData[field.serviceTypeServiceFieldId] || ""}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
              >
                <option value="">Select Purpose</option>
                <option value="Taste Profile Analysis">
                  Taste Profile Analysis
                </option>
                <option value="Product Testing">Product Testing</option>
                <option value="Quantity Testing">Quantity Testing</option>
              </select>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
          </div>
        );
      case "contact_number":
        return (
          <div key={field.fieldName}  className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6"
          }`}>
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
              <input
              type="number"
              placeholder={field.fieldName}
              value={formData[field.serviceTypeServiceFieldId]}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleContactBlur(e.target.value, field.serviceTypeServiceFieldId)
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
              
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">{errors[field.serviceTypeServiceFieldId]}</span>
            )}
          </div>
          </div>
        );
      case "email" :
          return (
            <div key={field.fieldName}  className={` ${
              (selectedServiceName === "Ship Barrels")
                ? "col-sm-6 col-md-4"
                : "col-sm-6"
            }`}>
              <div className="form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
                <input
                type="email"
                placeholder={field.fieldName}
                value={formData[field.serviceTypeServiceFieldId]}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                onBlur={(e) =>
                  handleEmailBlur(e.target.value, field.serviceTypeServiceFieldId)
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">{errors[field.serviceTypeServiceFieldId]} </span>
              )}
            </div>
            </div>
          );
      }
  };

  const updateSupportStatus = async (id: any, status: any) => {
    try {
      const response = await Service.updateSupportRequestStatus(id, status);
      setSelectedRequest(null);
      // loadSupportRequests(status);
      getOpenRequestCount();
      getClosedRequestCount();
      if (status == "OPEN") {
        CommonService.Toast.fire({
          title: `Ticket reopened successfully`,
          icon: "success",
        });
      } else {
        CommonService.Toast.fire({
          title: `Ticket closed successfully`,
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error fetching support requests:", error);
    }
  };

  // const loadSupportRequests = async (status: string) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await Service.getAllSupportRequests(
  //       CommonService.isUser() ? CommonService.getCompanyId() : null,
  //       status
  //     );
  //     setSupportRequests(response.data);

  //     if (response.data.length > 0) setSelectedRequest(response?.data[0]);

  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error fetching support requests:", error);
  //   }
  // };

  const getClosedRequestCount = async () => {
    try {
      const response = await Service.getCloseSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setClosedRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching closed request count:", error);
    }
  };

  const getOpenRequestCount = async () => {
    try {
      const response = await Service.getOpenSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setOpenRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching open request count:", error);
    }
  };

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
    setSelectedRequest(null);
    if (newValue !== "create") {
      setNewTicket(false);
      // loadSupportRequests(newValue.toUpperCase());
    }
  };

  // Filter the list whenever `search` changes
  const filteredItems = useMemo(
    () =>
      supportRequests.filter(
        (item) =>
          item.companyName.toLowerCase().includes(search.toLowerCase()) ||
          item.latestComment.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase())
      ),
    [supportRequests, search]
  );

  return (
    <div className="group-chat-wrapper support-main-wrapper">
           {CommonService.isAdmin() && (<><div className="d-flex align-items-center gap-3 ms-3 mb-2">
      <div className="back__button me-3" onClick={() => history.goBack()}>
          <IconButton
            aria-label="ArrowBackIcon"
            sx={{ backgroundColor: "#FFF", mb: 1 }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>
        </div><Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            color: "#242424",
            lineHeight: "22px",
          }}
        >
          {id ? "Update Customer Service Request" : "Create New Request"}
            
          </Typography>
        </div></>)}

      {/* <div className="d-flex"> */}
      <div className="group-chat-container service--req-wrapper" style={{minHeight:CommonService.isAdmin() ? "calc(100vh - 167px)" :"calc(100vh - 500px)"}}>
        {/* <div className="col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0 px-0 px-sm-2">
            <div
              className="group-list-wrapper"
              style={{
                height: isNewTicket === false ? "805px" : "initial",
                minHeight: CommonService.isAdmin() ? "895px" : "550px",
              }}
            >
              <nav className="group-chat-nav-wrapper d-flex align-items-end justify-content-between w-100 position-sticky top-0">
                <div
                  className="nav nav-tabs justify-content-between mt-3 w-100 px-2 px-sm-3"
                  id="nav-tab"
                  role="tablist"
                >
                  <div className="d-flex">
                    <button
                      onClick={() => handleTabChange("open")}
                      className={`nav-link ${
                        activeTab === "open" ? "active" : ""
                      }`}
                      id="nav-all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-all"
                      type="button"
                      role="tab"
                      aria-controls="nav-all"
                      aria-selected={activeTab === "open"}
                    >
                      {`Open (${openRequestCount})`}
                    </button>
                    <button
                      onClick={() => handleTabChange("close")}
                      className={`nav-link ${
                        activeTab === "close" ? "active" : ""
                      }`}
                      id="nav-warehouse-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-warehouse"
                      type="button"
                      role="tab"
                      aria-controls="nav-warehouse"
                      aria-selected={activeTab === "close"}
                    >
                      {`Close (${closedRequestCount})`}
                    </button>
                  </div>
                  {CommonService.isUser() && (
                    <button
                      onClick={() => {
                        setSelectedRequest(null);
                        setNewTicket(true);
                        setComment("");
                        setFiles(null);
                      }}
                      className="create-ticket-btn mb-2"
                      title="Create Ticket"
                    >
                      <AddIcon />
                      Create Ticket
                    </button>
                  )}
                </div>
              </nav>
              <div className="position-sticky ">
                <div className="position-relative mt-3 px-2 px-sm-3">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="chat-search mr-sm-2 pl"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "25px",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
              <div className="text-muted">
                <hr />
              </div>

              <ListGroup className="px-2 px-sm-3">
                {filteredItems?.map((request, index) => (
                  <ListGroup.Item
                    className="cursor-pointer"
                    onClick={() => {
                      setNewTicket(false);
                      setSelectedRequest(request);
                    }}
                  >
                    <div
                      className="chat-info-wrapper py-2 px-2"
                      style={{
                        backgroundColor:
                          selectedRequest?.supportRequestId ===
                          request.supportRequestId
                            ? "white"
                            : "initial",
                      }}
                    >
                      <div className="row m-0">
                        <div className="col-12 px-0">
                          <div className="d-flex justify-content-between">
                            <p className="request-name mb-0">{request?.name}</p>
                            <p className="request-ticketnumber mb-0">{`#${request?.requestNumber}`}</p>
                          </div>
                        </div>
                        <div className="col-12 px-0 mt-2">
                          <div className="row">
                            <div className="col-8">
                              <p className="msg-preview mb-0">
                                {request.latestComment}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="time__info d-flex flex-column text-end lh-base ms-auto">
                                <div className="date ">
                                  <p className="mb-1">
                                    {CommonService.formatDateForSupportRequest(
                                      request.createdDate
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    {CommonService.formatTimeForSupportRequest(
                                      request.createdDate
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {filteredItems.length <= 0 && (
                <NoTicketCreated
                  openRequestCount={openRequestCount}
                  closeRequestCount={closedRequestCount}
                />
              )}
            </div>
          </div> */}
        {/* <div className=""> */}
        <div className="container">
          {/* {selectedRequest && !isNewTicket && (
                <>
                  <WebSocketProvider
                    requestId={selectedRequest?.supportRequestId}
                    sellerCompanyId={null}
                  >
                    <div className="message-list-header d-flex align-items-center justify-content-between flex-wrap gap-2">
                      <div className="d-flex align-items-center">
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#333333",
                            lineHeight: "23.94px",
                          }}
                        >
                          Ticket #{selectedRequest?.requestNumber}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            fontWeight: 700,
                            fontSize: "18px",
                            lineHeight: "23.94px",
                            marginLeft: "10px",
                          }}
                        >
                          {selectedRequest.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            ml: 1,
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#333333",
                            lineHeight: "21.28px",
                          }}
                        >
                          | {selectedRequest.companyName}
                        </Typography>
                      </div>
                      <div className="ms-auto">
                        {selectedRequest.status == "OPEN" ? (
                          <button
                            className="close-btn d-flex align-items-center"
                            onClick={() => {
                              updateSupportStatus(
                                selectedRequest.supportRequestId,
                                "CLOSE"
                              );
                              setActiveTab("close");
                            }}
                            title="Close Ticket"
                          >
                            <CloseIcon /> Close
                          </button>
                        ) : (
                          <button
                            className="reopen-btn d-flex align-items-center"
                            onClick={() => {
                              updateSupportStatus(
                                selectedRequest.supportRequestId,
                                "OPEN"
                              );
                              setActiveTab("open");
                            }}
                            title="Reopen Ticket"
                          >
                            <ReplayIcon />
                            Re-open
                          </button>
                        )}
                      </div>
                      {/* Anderson Smith |  Max Inc 
                    </div>
                    <div className="message-list-card">
                      <SupportCommentBox
                        supportRequestId={selectedRequest.supportRequestId}
                        status={selectedRequest.status}
                      />
                    </div>
                  </WebSocketProvider>
                </>
              )}
              {!selectedRequest && !isNewTicket && !isLoading && (
                <div className="support-faq-wrapper">
                  <p className="heading">FAQ</p>
                  <FAQList />
                </div>
              )} */}

          {isNewTicket && (
            <div className="service-req--inner">
              {/* <div className="service-header">
                <h3 className="heading mb-3">New Service Request</h3>
              </div> */}

              <div className="service-req-form-wrapper">
                <form onSubmit={handleSubmit} className="service-req-form">
                  <div className=" form__field--wrapper">
                    <div className="row m-0">
                      <div className="col-lg-4">
                        {/* <div className="col-4"> */}
                        <label
                          htmlFor="service-dropdown"
                          className="form-label"
                        >
                          Select a Request
                          <span className="star-required text-danger">*</span>
                        </label>
                        <div className="w-100">
                          <select
                            id="service-dropdown"
                            value={selectedService}
                            onChange={(e) => {
                              const selectedServiceId = e.target.value;
                              const selectedServiceObject = services.find(service => service.id === selectedServiceId);
                              handleServiceChange(selectedServiceId, selectedServiceObject?.serviceName);
                            }}
                            className="select-dropdown w-100"
                            required
                            disabled = {id ? true : false}
                          >
                            <option value="">Select</option>
                            {services.map((service) => (
                              <option key={service.id} value={service.id}>
                                {service.serviceName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* </div> */}
                      </div>
                       
                      {CommonService.isAdmin() && isAdminSelectService && (
                        <div className="col-lg-4">
                        {/* <div className="col-4"> */}
                        <label
                          htmlFor="user-company-dropdown"
                          className="form-label"
                        >
                          Select a Company
                          <span className="star-required text-danger">*</span>
                        </label>
                        <div className="w-100">
                          <select
                            id="user-company-dropdown"
                            value={selectedCompany}
                            onChange={(e) => {
                              handleUserCompanyChange(e.target.value);
                            }}
                            className={`select-dropdown w-100 ${
                              selectedCompanyError
                                ? "is_invalid"
                                : ""
                            }`}
                            
                          >
                            <option value="">Select</option>
                            {companiesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                          </select>
                        </div>

                        {selectedCompanyError && (
                <span className="error-message ">{selectedCompanyError} </span>
              )}
                      </div>
                      )}

                    </div>
                  </div>

                  {isServiceFieldLoading && (
                    <div className="text-center py-4">Loading...</div>
                  )}

                  {!isServiceFieldLoading && fields.length > 0 && (
                    <div className="space-y-4 row m-0 g-4">
                      {fields.map((field) => renderField(field))}
                      <div className="req-footer-wrapper gap-3">
                        <button
                          type="reset"
                          className="secondary-btn"
                          onClick={() => history.goBack()}
                          disabled={isLoading}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="primary-btn"
                          disabled={isLoading}
                        >
                          {isLoading && <Spinner />} {id ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default SupportRequest;
