import React, { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import "../Dynamic/Dashboard.scss";
import OfferPercentage from "../../assets/images/icons/OfferPercentage.svg";
import SampleDrop from "../../assets/images/icons/Sample.svg";
import CloudArrowUpWhiteIcon from "../../assets/images/icons/CloudArrowUpWhite.svg";
import ShoppingCartSimple from "../../assets/images/icons/ShoppingCartSimple.svg";
import SellRequestSimple from "../../assets/images/icons/SellRequestSimple.svg";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import { useHistory, useLocation, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

interface UnreadMessageProps {
  inventoryCount: number;
}

const UnreadMessage: React.FC<UnreadMessageProps> = ({ inventoryCount }) => {
  const history = useHistory();
  const location = useLocation();
  const [unreadMessages, setUnreadMessages] = useState<any[]>([]);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);

  function route(route: string) {
    history.push(route);
  }

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const fetchUnreadMessages = async () => {
    try {
      setIsLoading(true);
      const params = CommonService.isUser() ? { id: CommonService.getCompanyId() } : { id };
      const response: any = await Service.fetchUnreadMessages(params, CommonService.getDashboardPrefrence());
      setUnreadMessages(response.data);

      const totalCount = response.data.reduce((sum: number, message: any) => {
        const messageCount = JSON.parse(message.messages[0]).length || 0;
        return sum + messageCount;
      }, 0);

      setTotalMessageCount(totalCount);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    }
  };

  useEffect(() => {
    fetchUnreadMessages();
  }, [location.pathname]);

  return (

    <div className="col-xl-5 col-xxl-4 position-relative">
                {!isLoading ? (
                  <>
                    {CommonService.getDashboardPrefrence() == 'Owner' && inventoryCount == 0 && CommonService.isUser() && (<div className="dashboard__uploadInvBanner mb-5 mb-xl-0" >
        <div className="title">
          <h4 className="lh-base fw-bold fs-5">Sell Your Inventory </h4>
        </div>
        <div className="banner p-5">
          <div className="banner-content text-center w-100">
            <h4 className="fs-2 text-white fw-bolder mb-4">
              Take the first step by submitting <br />
              your inventory to sell
            </h4>
            <Link to="/user/inventory-sell-requests" className="primary-btn d-inline-flex align-items-center fw-semibold px-3 py-2">
              <img src={CloudArrowUpWhiteIcon} alt="CloudArrowUpWhiteIcon" className="me-1" />
              Upload Inventory for Sell
            </Link>
          </div>
        </div>
      </div> )}
      <div className={`overflow-y-auto ${CommonService.isUser() ? "company-dashboard__messageBox-wrapper" : "dashboard__messageBox-wrapper"} ${(CommonService.getDashboardPrefrence() == 'Owner' && inventoryCount == 0) ? "owner-data" :"buyer-data" }`}> 
        <div className="dashboard__messageBox mb-5 mb-xl-0">
        <div className="dashboard__messageBox--title bg-white position-sticky top-0">
          <h4 className="mb-0 lh-base">Unread Messages</h4>
          <div className="msg-count position-relative cursor-pointer" onClick={() => CommonService.isAdmin() ? route('/master/messages') : route('/user/messages')}>
            <MailOutlineSharpIcon sx={{ color: "#97AEB8" }} />
            <span className="count position-absolute top-0 start-100 translate-middle badge">
              {totalMessageCount}
            </span>
          </div>
        </div>
        {unreadMessages.map((message: any) => (
          <div className="dashboard__messageBox--wrapper">
            <div className="row d-flex m-0">
              <div className="col-auto px-1 ps-0">
                <div className="user__avatar">
                  {message?.createdBy?.charAt(0)}
                </div>
              </div>
              <div className="col-auto px-1">
                <div className="d-flex flex-column">
                  <div className="user__name">{message.createdBy}</div>
                  <div className="d-flex">
                    <div className="user__companyname me-1">
                      {message.companyName}
                    </div>
                    <div>|</div>
                    <div className="user__reqType ms-1">
                      {message.requestType == "OFFER_REQUEST" && (
                        <img src={OfferPercentage} alt="OfferPercentage" />
                      )}

                      {message.requestType == "SAMPLE_REQUEST" && (
                        <img src={SampleDrop} alt="SampleDrop" />
                      )}

                      {message.requestType == "ORDER_REQUEST" && (
                        <img src={ShoppingCartSimple} alt="ShoppingCartSimple" />
                      )}

                      {message.requestType == "SELL_REQUEST" && (
                        <img src={SellRequestSimple} alt="SellRequestSimple" />
                      )}

                      {toTitleCase(message.requestType)}
                    </div>
                  </div>
                  {/* <div className="user__message">
                    <p>
                      {
                        JSON.parse(message.messages[0])[
                        JSON.parse(message.messages[0]).length - 1
                        ]
                      }
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-auto px-1 pe-0 ms-auto">
                <div className="user__requestTime text-end">
                  {/* {message.modifiedDate} */}
                  <span className="date">
                    {message.modifiedDate.split(" | ")[0]}
                  </span>
                  <br />
                  <span className="time">
                    {message.modifiedDate.split(" | ")[1]}
                  </span>
                </div>
              </div>
              <div className="col-12">
              <div className="user__message mt-2">
                    <p>
                      {
                        JSON.parse(message.messages[0])[
                        JSON.parse(message.messages[0]).length - 1
                        ]
                      }
                    </p>
                  </div>
                  </div>
              {/* <div className="request__header">{message.companyName}</div>
                    <div className="details">{message.createdBy} | {message.requestType}</div>
                    <ul className="message-list">
                        {message.messages.map((msg: any) => (
                            <li className="message-item">{msg}</li>
                        ))}
                    </ul>
                    <div className="details">{message.modifiedDate}</div> */}
            </div>
          </div>
        ))}
      </div>
      {totalMessageCount == 0 && (<p className="no-record">No Message Found</p>)}
      </div>

                    {/* Other cards here */}
                  </>
                ) : (
                  <Skeleton animation="wave"
          variant="rounded"
          style={{
            width: "100%", // Matches the width of the parent
            height: "100%", // Matches the height of the parent
            minHeight: "300px", // Optional fallback for height if parent is dynamic
          }}
          className="skeleton-loader"
        />
                )}
              </div>
  );
};
export default UnreadMessage;