import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";

import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
} from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Constant from "../../Constant";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Skeleton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const OfferRequest = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const history = useHistory();
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const controllerRef = useRef<AbortController | null>(null);
  const [filter, setFilter] = useState<any>({
    inventoryName: null,
    requestNumber: null,
    companyName: null,
    firstName: null,
    requestedOn: null,
    quantity: null,
    pricePerBarrel: null,
    totalAmount: null,
    status: null,
  });
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const fetchAllActiveOfferRequests = async (page:any,size:any, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getActiveOfferRequests(page,size,false, filter, signal);
      approved = Object.values(response.data.content); // Convert object to array
      approvedUserRef.current = approved;
      setTotalCount(response.data.totalElements);
      // Access updated value of approvedUser using approvedUserRef.current
      setTableData(approvedUserRef.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/view-offer-request/${params.row.id}`);
  }

  const openOfferRequests = async (page,size, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getCompanyWiseOfferRequests(
        params,
        false,CommonService.getDashboardPrefrence(),page, size, filter, signal
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {

    if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
      openOfferRequests(page,pageSize, filter);
    else 
      fetchAllActiveOfferRequests(page,pageSize, filter);

  }, []);
  

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tootip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //this code is for dropdown
  const [rowStatusMap, setRowStatusMap] = React.useState<{
    [key: string]: string;
  }>({});

  const handleStatusChange = async (newStatus: string, rowId: string) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Accepted",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
    };

    console.log("this is status" + orderStatus);

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOfferStatus();
    }
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
  };

  const changeOfferStatus = async () => {
    if (rejectionNoteValue.trim().length == 0 && statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      console.log("Come inside it");
      setError("Please enter rejection note");
      return;
    }

    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      console.log(statusDetailsRef.current);
      await OrderApiService.changeOfferStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
      }
      console.log("status updated successfully");
      handleClose();
      CommonService.Toast.fire({
        title: "Offer status updated successfully",
        icon: "success",
      });
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    console.log(formattedStatus);
    return formattedStatus;
  }

  const columns: GridColDef[] = [
    {
      field: "inventoryName",
      headerName: "Inventory",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.inventoryName}
                    onChange={(event) => handleFilterChange(event, "inventoryName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value}/>
    },
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.requestNumber}
                    onChange={(event) => handleFilterChange(event, "requestNumber")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value || '-'}/>
    },

    ...(CommonService.isAdmin()
      ? [
      {
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    } ,
    {
      field: "firstName",
      headerName: "Requested By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    } ]
    : []),
    {
      field: "createdDate",
      headerName: "Requested On",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.isUser() && !CommonService.isSameCompany(row.companyId) ?  CommonService.formatStrictDate(dateTimeString) : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="birthdate"
                placeholder="MM/DD/YYYY"
                value={filter.requestedOn}
                onChange={(event) => handleFilterChange(event, "requestedOn")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-IN"
          currency="INR"
        />
      ),
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.quantity}
                    onChange={(event) => handleFilterChange(event, "quantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "pricePerBarrel",
      headerName: "Offer Price",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.pricePerBarrel}
                    onChange={(event) => handleFilterChange(event, "pricePerBarrel")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin()
      ? [
    {
      field: "totalAmount",
      headerName: "Total Price",
      headerClassName: "table-header border-0 my-2 h-100",
      flex: 1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    value={filter.totalAmount}
                    onChange={(event) => handleFilterChange(event, "totalAmount")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    }]
    : []),
    {
      field: "status",
      headerName: "Order Status",
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "status-badge",
      flex: 1,
      renderCell: (params: any) => (
        <div className="cell-with-dropdown mb-2" onClick={(e) => e.stopPropagation()}>

          {/* Pending Review - User */}
          {(params.row.status === "PENDING_REVIEW" || params.row.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id
                )
              }
              disableCondition={CommonService.isAdmin() || !CommonService.isSameCompany(params.row.companyId)}
              allowedLabels={["Cancelled"]}
            />
          )}

          {((params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id
                )
              }
              disableCondition={CommonService.isUser() || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || (params.row.status === "UNDER_REVIEW" && params.row?.hasValidStatus)}
              allowedLabels={params.row?.hasValidStatus ? ["Under Review"] : params.row?.hasItemRejected ? ["Under Review", "Rejected"] : ["Under Review","Accepted", "Rejected"]}
            />
          )}

          {/* Under Review - User (disabled) */}
          {( params.row?.status === "UNDER_REVIEW" && CommonService.isUser()) && (
              <StatusDropdown
                currentStatus={formatStatus( params.row?.status)}
                onStatusChange={(newStatus) => handleStatusChange(newStatus,  params.row.id)}
                disableCondition={true}
                allowedLabels={[]}
              />
          )}

          {/* Accepted to Payment Received - Both User and Admin */}
          {["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED"].includes( params.row?.status) && (
            <StatusDropdown
              currentStatus={formatStatus( params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id)}
              disableCondition={CommonService.isUser() ||  params.row?.status === "CANCELLED" ||  params.row?.status === "REJECTED"}
              allowedLabels={["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Rejected"]}
            />
          )}

          {/* Accepted - Admin specific options */}
          { params.row?.status === "PAYMENT_RECEIVED" && (
            <StatusDropdown
              currentStatus={formatStatus( params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id)}
              disableCondition={CommonService.isUser() ||  params.row?.status === "CANCELLED" ||  params.row?.status === "REJECTED"}
              allowedLabels={["Ownership Transferred", "Rejected"]}
            />
          )}
    
          {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
          {( params.row?.status === "OWNERSHIP_TRANSFERRED" ||  params.row?.status === "CANCELLED") && (
            <StatusDropdown
              currentStatus={formatStatus( params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id)}
              disableCondition={true}
              allowedLabels={[]}
            />
          )}

          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
      renderHeader: (params) => (
        <>
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative">
                    <Form.Select
                        style={{ cursor: "pointer" }}
                        value={filter.status}
                        className="form-control mb-1"
                        onChange={(event) => handleFilterChange(event, "status")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                        <option value="">Select Status</option>
                        <option value="PENDING_REVIEW">Pending Review</option>
                        <option value="UNDER_REVIEW">Under Review</option>
                        <option value="ACCEPTED">Accepted</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="CONTRACT_SENT">Contract Sent</option>
                        <option value="INVOICE_SENT">Invoice Sent</option>
                        <option value="PAYMENT_RECEIVED">Payment Received</option>
                        <option value="CONTRACT_EXECUTED">Contract Executed</option>
                        <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
                        <option value="CANCELLED">Cancelled</option>
                    </Form.Select>
                </div>
            </div>
        </>
      ),
    },
  ];
//.filter(Boolean) as GridColDef[]
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.status === "INVITED");
    disableAction.current = flag;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;

    console.log(rejectionNoteValue);
  };

  // // Filter rows based on search query
  // const filteredRows = tableData.filter((row) =>
  //   CommonService.isAdmin()
  //     ? row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.inventoryName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.firstName + " " + row.lastName)
  //         .toLowerCase()
  //         .includes(search?.toLowerCase())
  //     : row.inventoryName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData.map((rowData, index) => ({
    ...rowData,
  }));

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;

    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };
  
    // Update the filter state
    setFilter(tempFilter);

    // Load the service requests with updated filters
    if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
      openOfferRequests(page,pageSize, tempFilter);
    else 
      fetchAllActiveOfferRequests(page,pageSize, tempFilter);
  };

  const handleBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className="usersList" style={{paddingBottom:CommonService.isAdmin() ? 51 : ""}}>
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          {CommonService.isAdmin() && (<div className="header--title mb-2 me-2">
            <h1 className="title mb-0 ms-2">
              Offer Requests
            </h1>
          </div>)}
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: pageSize },
              },
            }}
            pagination
            paginationModel={{
              page,
              pageSize,
            }}
            rowCount={totalCount}
            paginationMode="server"
            onPaginationModelChange={(newPaginationModel) => {
              setPage(newPaginationModel.page);
              setPageSize(newPaginationModel.pageSize);

              if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
                openOfferRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);
              else 
                fetchAllActiveOfferRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);

            }}
            pageSizeOptions={[5, 10]}
            localeText={localeText}
            // checkboxSelection
            disableRowSelectionOnClick={true}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            loading={isLoading}
            // getRowClassName={getRowClassName}
          />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleInputChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter rejection note ..."
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOfferStatus}>
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OfferRequest;
