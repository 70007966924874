import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CookieService } from "../../Service/CookieService";
import { OrderApiService } from "../../Service/OrderApiService";
import { ButtonGroup } from "react-bootstrap";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import { CommonService } from "../../Service/CommonService";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import ViewProductDetail from "./ViewProductDetail";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MessageApiService } from "../../Service/MessageApiService";

const ViewSampleRequest: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const userRole = CookieService.getCookie("userRole");
  const [sampleRequest, setSampleRequest] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleRequestDocs, setSampleRequestDocs] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [ownerStatus, setOwnerStatus] = useState("");
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [isOwnerRejected, setOwnerRejected] = useState(false);
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [ownerRejectionNote, setOwnerRejectionNote] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const handleViewClose = () => setViewDetails((prev) => !prev);
  const statusDetailsRef = useRef({
    id: "",
    inventoryId: "",
    sampleRequestStatus: "",
    trackNumber: "",
    rejectionNote: "",
  });
  const handleShow = () => setShow(true);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
  const handleClose = () => {
    setShow(false);
    setOwnerRejected(false);
    setOwnerRejectionNote("");
    setError("");
  };

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const routeBack = () => {
    if (userRole == "ADMIN") history.push("/user/sample-request");
    else history.push("/master/sample-request");
  };

  const fetchData = async () => {
    try {
      fetchSampleRequestDetail();
      fetchSampleRequestDocs();
      fetchSampleRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSampleRequestDetail = async () => {
    const response: any = await OrderApiService.fetchSampleRequestById(id);
    setSampleRequest(response.data);
    const hasSeller =
      !CommonService.isSameCompany(response.data?.companyId) &&
      CommonService.isUser()
        ? true
        : false;
    setIsSeller(hasSeller);
  };

  const fetchSampleRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchSampleRequestDocs(id);
    setSampleRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchSampleRequestHistory = async () => {
    const response: any = await OrderApiService.fetchSampleRequestHistory(id);
    setHistoryData(response.data);
  };

  const getAdminUnreadMessageGroupsCount = async () => {
    try {
      const response = await MessageApiService.getAdminMessageGroups(
        id,
        "SAMPLE_REQUEST"
      );

      setUnreadGroupsCount(response.data.unreadGroupsCount);
      setUnreadMessageCount(response.data.unreadMessageCount);
    } catch (error) {
      console.log("error fetching Admin Message Groups Count" + error);
    }
  };

  useEffect(() => {
    fetchData();
    if (CommonService.isAdmin()) getAdminUnreadMessageGroupsCount();
  }, []);

  const handleStatusChange = async (requestStatus: string) => {
    const sampleRequestStatus = requestStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      inventoryId: sampleRequest.inventoryId,
      sampleRequestStatus: sampleRequestStatus.toUpperCase(),
      companyId: CommonService.getCompanyId(),
      trackNumber: "",
      rejectionNote: "",
    };

    statusDetailsRef.current = sd;
    if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setIsApproved(false);
      handleShow();
    } else if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.ACCEPTED
    ) {
      setIsApproved(true);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeSampleRequestStatus();
    }
  };

  const changeSampleRequestStatus = async () => {
    if (
      statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.REJECTED &&
      rejectionNoteValue.trim().length === 0
    ) {
      setError("Please enter rejection note");
      return;
    }

    if (
      statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.ACCEPTED &&
      trackNumberValue.trim().length === 0
    ) {
      setError("Please enter track number");
      return;
    }

    setIsSpinner(true);
    try {
      if (
        statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.REJECTED
      ) {
        statusDetailsRef.current.rejectionNote = rejectionNote.current;
      } else if (
        statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.ACCEPTED
      ) {
        statusDetailsRef.current.trackNumber = trackNumber.current;
      }

      await OrderApiService.changeSampleRequestStatus(statusDetailsRef.current);
      setIsSpinner(false);
      CommonService.Toast.fire({
        title: "Sample Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchSampleRequestDetail();
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  const statusMappings = {
    "Send to Owner": "PENDING_REVIEW",
    "Accept for Owner": "ADMIN_ACCEPTED",
    "Reject for Owner": "ADMIN_REJECTED",
  };

  const handleOwnerStatusChange = async (ownerStatus: any) => {
    ownerStatus = statusMappings[ownerStatus] || ownerStatus;
    const sampleOwnerStatus = ownerStatus?.replace(/ /g, "_").toUpperCase();

    setOwnerStatus(sampleOwnerStatus);

    if (
      sampleOwnerStatus == "ADMIN_REJECTED" ||
      sampleOwnerStatus == "REJECTED"
    ) {
      setOwnerRejected(true);
      return;
    }

    //create the object to send
    const requestData = {
      id: id,
      inventoryId: sampleRequest.inventoryId,
      ownerStatus: sampleOwnerStatus.toUpperCase(),
      companyId: CommonService.getCompanyId(),
    };

    try {
      await OrderApiService.updateSampleRequestOwnerStatus(requestData);
      setIsSpinner(false);
      CommonService.Toast.fire({
        title: "Owner status updated successfully",
        icon: "success",
      });
      // handleClose();
      fetchSampleRequestDetail();
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

  const changeSampleRequestOwnerStatus = async () => {
    //perform the validation of owner rejection note
    if (!ownerRejectionNote || ownerRejectionNote?.trim() == "") {
      setError("Please enter rejection note");
      return;
    }

    setIsSpinner(true);

    //create the object to send
    const requestData = {
      id: id,
      inventoryId: sampleRequest.inventoryId,
      ownerStatus: ownerStatus,
      ownerRejectionNote: ownerRejectionNote,
      companyId: CommonService.getCompanyId(),
    };

    try {
      await OrderApiService.updateSampleRequestOwnerStatus(requestData);
      setIsSpinner(false);
      CommonService.Toast.fire({
        title: "Owner status updated successfully",
        icon: "success",
      });
      handleClose();
      fetchSampleRequestDetail();
    } catch (error) {
      console.log("error occurred which api calling");
      setIsSpinner(false);
    }
  };

  //handle edit button click
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox((prev) => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(
      unreadGroupsCount > 0 && unreadMessageCount > 0
        ? unreadGroupsCount - 1
        : unreadGroupsCount
    );
  };

  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }
    return formattedStatus;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setError("");
    if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setNote(event.target.value);
      rejectionNote.current = event.target.value;
    } else {
      setTrackNumber(event.target.value);
      trackNumber.current = event.target.value;
    }
  };

  const handleOwnerRejectionNote = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setError("");
    setOwnerRejectionNote(event.target.value);
  };

  const downloadSampleRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: sampleRequest.siteId,
      companyId: sampleRequest.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadSampleRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadSampleRequestDOC(params.row)}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="usersList position-relative">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center">
          {CommonService.isAdmin() && (
            <div className="back__button me-3" onClick={routeBack}>
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-3">
              <h1 className="title mb-0">
                Sample Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
            <div className="time__details me-4 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Request Number
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                #{sampleRequest?.requestNumber}
              </Typography>
            </div>
            {(CommonService.isSameCompany(sampleRequest?.companyId) ||
              CommonService.isAdmin()) && (
              <>
                <div className="company__details me-4 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Company name
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "#333333",
                      fontSize: { xl: "18px", xs: "16px" },
                      fontWeight: 700,
                    }}
                  >
                    {sampleRequest?.companyName}
                  </Typography>
                </div>

                <div className="person__details me-4 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Submitted by
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {sampleRequest?.firstName}
                  </Typography>
                </div>
              </>
            )}
            <div className="time__details me-4 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {!isSeller
                  ? CommonService.formatDateTime(sampleRequest?.createdDate)
                  : CommonService.formatDateTime(
                      historyData[historyData.length - 1]?.histories[
                        historyData[historyData.length - 1].histories.length - 1
                      ]?.createdDate
                    )}
              </Typography>
            </div>
          </div>

          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              <IconButton
                aria-label="message"
                size="small"
                onClick={
                  CommonService.isAdmin()
                    ? handleAdminMessageClick
                    : handleMessageBoxClick
                }
                sx={{
                  borderRadius: "50%",
                  backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                  color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                  marginInline: "8px",
                  padding: "8px",
                }}
              >
                <MarkunreadOutlinedIcon />
                {unreadGroupsCount != 0 && CommonService.isAdmin() && (
                  <div className="msg-count-wrapper">{unreadGroupsCount}</div>
                )}
                {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
              </IconButton>
              {showMessageBox && (
                <div className="admin-message-box">
                  <div
                    className="admin-message-box-left-inner"
                    onClick={handleAdminMessageClick}
                  ></div>
                  <div className="admin-message-box-inner positio-relative">
                    <AdminMessageBox
                      selectedGroup={""}
                      requestId={id}
                      requestType={"SAMPLE_REQUEST"}
                      unreadGroupsCount={unreadGroupsCount}
                      setUnreadGroupsCount={setUnreadGroupsCount}
                      isSeller={isSeller}
                    />
                  </div>
                </div>
              )}
              {/* Pending Review - User */}
              {(sampleRequest?.sampleRequestStatus === "PENDING_REVIEW" ||
                sampleRequest?.sampleRequestStatus === "ACCEPTED_REJECTED") &&
                CommonService.isUser() && (
                  <StatusDropdown
                    currentStatus={formatStatus(
                      sampleRequest?.sampleRequestStatus
                    )}
                    onStatusChange={(newStatus) =>
                      handleStatusChange(newStatus)
                    }
                    disableCondition={
                      CommonService.isAdmin() ||
                      !CommonService.isSameCompany(sampleRequest?.companyId)
                    }
                    allowedLabels={["Cancelled"]}
                  />
                )}

              {/* Pending Review or Under Review - Admin */}
              {(sampleRequest?.sampleRequestStatus === "PENDING_REVIEW" ||
                sampleRequest?.sampleRequestStatus === "UNDER_REVIEW") &&
                CommonService.isAdmin() && (
                  <StatusDropdown
                    currentStatus={formatStatus(
                      sampleRequest?.sampleRequestStatus
                    )}
                    onStatusChange={(newStatus) =>
                      handleStatusChange(newStatus)
                    }
                    disableCondition={
                      CommonService.isUser() ||
                      sampleRequest?.sampleRequestStatus === "CANCELLED" ||
                      sampleRequest?.sampleRequestStatus === "REJECTED" ||
                      (sampleRequest?.sampleRequestStatus === "UNDER_REVIEW" &&
                        sampleRequest?.hasValidStatus)
                    }
                    allowedLabels={
                      sampleRequest?.hasValidStatus
                        ? ["Under Review"]
                        : sampleRequest?.hasItemRejected
                        ? ["Under Review", "Rejected"]
                        : ["Under Review", "Accepted", "Rejected"]
                    }
                  />
                )}

              {/* Under Review - User (disabled) */}
              {sampleRequest?.sampleRequestStatus === "UNDER_REVIEW" &&
                CommonService.isUser() && (
                  <StatusDropdown
                    currentStatus={formatStatus(
                      sampleRequest?.sampleRequestStatus
                    )}
                    onStatusChange={(newStatus) =>
                      handleStatusChange(newStatus)
                    }
                    disableCondition={true}
                    allowedLabels={[]}
                  />
                )}

              {/* Accepted to Payment Received - Both User and Admin */}
              {["ACCEPTED", "REJECTED", "IN_TRANSIT", "INVOICE_SENT"].includes(
                sampleRequest?.sampleRequestStatus
              ) && (
                <StatusDropdown
                  currentStatus={formatStatus(
                    sampleRequest?.sampleRequestStatus
                  )}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={
                    CommonService.isUser() ||
                    sampleRequest?.sampleRequestStatus === "CANCELLED" ||
                    sampleRequest?.sampleRequestStatus === "REJECTED"
                  }
                  allowedLabels={[
                    "Invoice Sent",
                    "Payment Received",
                    "In Transit",
                    "Rejected",
                  ]}
                />
              )}

              {sampleRequest?.sampleRequestStatus === "PAYMENT_RECEIVED" && (
                <StatusDropdown
                  currentStatus={formatStatus(
                    sampleRequest?.sampleRequestStatus
                  )}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Ownership Transferred", "Rejected"]}
                />
              )}

              {(sampleRequest?.sampleRequestStatus === "DELIVERED" ||
                sampleRequest?.sampleRequestStatus === "CANCELLED") && (
                <StatusDropdown
                  currentStatus={formatStatus(
                    sampleRequest?.sampleRequestStatus
                  )}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={true}
                  allowedLabels={[]}
                />
              )}

              {sampleRequest?.sampleRequestStatus === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${sampleRequest?.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="viewSampleRequest--wrapper view_request--wrapper view_sample-wrapper viewOrderRequest--wrapper">
          <div
            className={`${
              isOpenEditSection
                ? "margin-right-400" // When only `isOpenEditSection` is true
                : isMessageBoxOpen
                ? "margin-right-400" // Only `isMessageBoxOpen` is true
                : "no-margin w-100" // When none of the conditions are met
            }`}
          >
            <div
              className="view-sample-request mb-3"
              style={{ zIndex: "10", position: "relative" }}
            >
              {/* <div className="row m-0">
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Purpose
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.purpose}</div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Is Multiple barrels from same lot?
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {sampleRequest?.multipleBarrels ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Is Multiple lots needed?
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {sampleRequest?.multiplelots ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Size Needed
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.size}</div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Qty Requested of Size
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.quantity}</div>
                  </div>
                </div>
                {CommonService.isAdmin() && (<><div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Request Notes
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.comment}</div>
                  </div>
                </div><div className="col-sm-6 col-lg-4">
                    <div className="sample-request-faq py-3">
                      <div className="faq-title position-relative pb-1 mb-3">
                        Owner Status
                        <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                      </div>
                      <div className="faq-desc">
                        {sampleRequest?.ownerStatus}
                      </div>
                    </div>
                  </div></>)}
              </div> */}
              <div className="row m-0 pt-3">
                <div className="col-12 px-0">
                  <div>
                    <div>
                      <div className="inventory-file-list-table">
                        <div className="table-responsive-lg table-responsive-fixed-header mb-lg-3">
                          {!sampleRequest ? (
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              // height={550}
                              style={{ height: "100%" }}
                            />
                          ) : (
                            <Table className="orderRequest-inv-table text-center">
                              <thead>
                                <tr>
                                  <th className="text-nowrap text-start">
                                    Inventory
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Purpose
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Is Multiple barrels from same lot?
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Is Multiple lots needed?
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Size Needed
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Qty Requested of Size
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Request Notes
                                  </th>
                                  {(isSeller || CommonService.isAdmin()) && (
                                    <th className="text-nowrap text-center">
                                      Owner Status
                                    </th>
                                  )}
                                  <th className="text-nowrap text-center">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {sampleRequest ? (
                                  <tr>
                                    <td className="text-start">
                                      {sampleRequest?.inventoryName}
                                      <Typography
                                        component="p"
                                        sx={{
                                          color: "#737376",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          lineHeight: "21.28px",
                                        }}
                                      >
                                        {
                                          sampleRequest?.inventoryOwnerCompanyName
                                        }
                                      </Typography>
                                    </td>
                                    <td className="text-center">
                                      {sampleRequest?.purpose}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      {sampleRequest?.multipleBarrels ? (
                                        <>
                                          <CheckIcon
                                            fontSize="small"
                                            sx={{ color: "#25CD25" }}
                                          />{" "}
                                          Yes
                                        </>
                                      ) : (
                                        <>
                                          <CloseIcon
                                            fontSize="small"
                                            sx={{ color: "#FF3B30" }}
                                          />{" "}
                                          No
                                        </>
                                      )}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      {sampleRequest?.multiplelots ? (
                                        <>
                                          <CheckIcon
                                            fontSize="small"
                                            sx={{ color: "#25CD25" }}
                                          />{" "}
                                          Yes
                                        </>
                                      ) : (
                                        <>
                                          <CloseIcon
                                            fontSize="small"
                                            sx={{ color: "#FF3B30" }}
                                          />{" "}
                                          No
                                        </>
                                      )}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      {sampleRequest?.size}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      {sampleRequest?.quantity}
                                    </td>
                                    <td className="text-center">
                                      {sampleRequest?.comment}
                                    </td>
                                    {(isSeller || CommonService.isAdmin()) && (
                                      <>
                                        <td className="text-nowrap text-center">
                                          {CommonService.isAdmin() && (
                                            <StatusDropdown
                                              currentStatus={formatStatus(
                                                sampleRequest?.ownerStatus
                                              )}
                                              onStatusChange={(newStatus) =>
                                                handleOwnerStatusChange(
                                                  newStatus
                                                )
                                              }
                                              disableCondition={
                                                CommonService.isSameCompany(
                                                  sampleRequest?.companyId
                                                ) ||
                                                [
                                                  "REJECTED",
                                                  "ADMIN_REJECTED",
                                                  "ACCEPTED",
                                                ].includes(
                                                  sampleRequest?.ownerStatus
                                                ) ||
                                                [
                                                  "ACCEPTED",
                                                  "REJECTED",
                                                  "IN_TRANSIT",
                                                  "INVOICE_SENT",
                                                  "PAYMENT_RECEIVED",
                                                  "DELIVERED",
                                                  "CANCELLED",
                                                ].includes(
                                                  sampleRequest?.sampleRequestStatus
                                                )
                                              }
                                              allowedLabels={
                                                sampleRequest?.ownerStatus ===
                                                "ADMIN_ACCEPTED"
                                                  ? ["Reject for Owner"]
                                                  : sampleRequest?.ownerStatus ===
                                                    "PENDING_REVIEW"
                                                  ? [
                                                      "Reject for Owner",
                                                      "Accept for Owner",
                                                    ]
                                                  : [
                                                      "Reject for Owner",
                                                      "Accept for Owner",
                                                      "Send to Owner",
                                                    ]
                                              }
                                            />
                                          )}

                                          {CommonService.isUser() && (
                                            <StatusDropdown
                                              currentStatus={formatStatus(
                                                sampleRequest?.ownerStatus
                                              )}
                                              onStatusChange={(newStatus) =>
                                                handleOwnerStatusChange(
                                                  newStatus
                                                )
                                              }
                                              disableCondition={
                                                CommonService.isSameCompany(
                                                  sampleRequest?.companyId
                                                ) ||
                                                [
                                                  "REJECTED",
                                                  "ADMIN_REJECTED",
                                                ].includes(
                                                  sampleRequest?.ownerStatus
                                                ) ||
                                                [
                                                  "ACCEPTED",
                                                  "REJECTED",
                                                  "IN_TRANSIT",
                                                  "INVOICE_SENT",
                                                  "PAYMENT_RECEIVED",
                                                  "DELIVERED",
                                                  "CANCELLED",
                                                ].includes(
                                                  sampleRequest?.sampleRequestStatus
                                                )
                                              }
                                              allowedLabels={
                                                sampleRequest?.ownerStatus ===
                                                "ADMIN_ACCEPTED"
                                                  ? ["Rejected"]
                                                  : ["Accepted", "Rejected"]
                                              }
                                            />
                                          )}

                                          {(sampleRequest?.ownerStatus ===
                                            "REJECTED" ||
                                            sampleRequest?.ownerStatus ===
                                              "ADMIN_REJECTED") && (
                                            <Tooltip
                                              title={`Rejection Note: ${sampleRequest.ownerRejectionNote}`}
                                              arrow
                                            >
                                              <StickyNote2Icon
                                                className="note-icon cursor-pointer"
                                                style={{ color: "gray" }}
                                              />
                                            </Tooltip>
                                          )}
                                        </td>
                                      </>
                                    )}
                                    <td className="text-nowrap text-center">
                                      <div className="d-flex justify-content-center gap-3">
                                        {(sampleRequest?.sampleRequestStatus ===
                                          "PENDING_REVIEW" ||
                                          sampleRequest?.sampleRequestStatus ===
                                            "UNDER_REVIEW") && (
                                          <BorderColorIcon
                                            onClick={handleEditButtonClick}
                                            className="cursor-pointer"
                                          />
                                        )}
                                        <VisibilityOutlined
                                          onClick={() => {
                                            handleViewClose();
                                          }}
                                          className="cursor-pointer"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td colSpan={12} className="text-center">
                                      Fetching records ...
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          )}
                        </div>
                        {viewDetails && (
                          <ViewProductDetail
                            handleViewClose={handleViewClose}
                            inventoryId={sampleRequest?.inventoryId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-3 m-0">
                <div className="col-xl-6 mb-3 mb-xl-0 ps-0">
                  <div className="orderDetail-footer-content-wrapper">
                    <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">List of Documents</h3>
                      <UploadDocs
                        fetchData={fetchData}
                        csvId={id}
                        uploadCSV={false}
                        requestType={"SAMPLE_REQUEST"}
                      />
                    </div>
                    <div
                      className="inventory-doc-table"
                      style={{ height: 350, width: "100%" }}
                    >
                      {isLoading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            // height={550}
                            style={{ height: "100%" }}
                          />
                        </>
                      ) : (
                        <>
                          <DataGrid
                            localeText={localeText}
                            rows={sampleRequestDocs}
                            columns={columnsForDoc}
                            className="data-grid"
                            hideFooterPagination
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 mb-3 mb-xl-0 pe-0">
                  <div className="recentActivity">
                    <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-2">Recent Activity</h3>
                    </div>
                    <div className="history-stepper-wrapper">
                      <HistoryStepper historyData={historyData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5 mb-3 m-0">
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"SAMPLE_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={sampleRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                  <HistoryStepper historyData={historyData} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
            <div className="msgContainerBox view-sample-msgContainerBox">
              {isOpenEditSection && (
                <EditRequest
                  requestType={"SAMPLE_REQUEST"}
                  id={id}
                  refreshData={fetchData}
                  onEditClick={handleEditButtonClick}
                />
              )}
              {isMessageBoxOpen && (
                <ContainerMessageBox
                  requestType={"SAMPLE_REQUEST"}
                  sellerCompanyId={
                    isSeller ? CommonService.getCompanyId() : null
                  }
                  isSeller={isSeller}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Status Change Model Pop-up */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Sample Request Status</Modal.Title>
          {isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Approved?
            </p>
          )}
          {!isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Rejected?
            </p>
          )}
        </Modal.Header>

        <Modal.Body className="pb-1">
          <div className="form__inner pb-0">
            {isApproved && (
              <>
                <label className="modal-body__title mb-1">Track Number</label>
                <Form.Control
                  className={`form-control ${error ? "is_invalid" : ""}`}
                  type="text"
                  value={trackNumberValue}
                  onChange={handleInputChange}
                  placeholder="Enter Tracking Number..."
                />
              </>
            )}
            {isApproved && error && (
              <div className="error-message"> {error}</div>
            )}

            {!isApproved && (
              <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                value={rejectionNoteValue}
                onChange={handleInputChange}
                rows={8}
                style={{ resize: "none" }}
                placeholder="Enter Rejection Note..."
              />
            )}
            {!isApproved && error && (
              <div className="error-message"> {error}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isSpinner}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeSampleRequestStatus}
                disabled={isSpinner}
              >
                {isSpinner && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOwnerRejected}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Owner Status</Modal.Title>

          <p className="modal-text">
            Are you sure, you want to change status to Rejected?
          </p>
        </Modal.Header>

        <Modal.Body className="pb-1">
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={ownerRejectionNote}
              onChange={handleOwnerRejectionNote}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter Rejection Note..."
            />

            {error && <div className="error-message"> {error}</div>}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isSpinner}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeSampleRequestOwnerStatus}
                disabled={isSpinner}
              >
                {isSpinner && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewSampleRequest;
