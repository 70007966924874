import React, { useEffect, useRef, useState } from "react";
import { OrderApiService } from "../../Service/OrderApiService";
import { useHistory, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { ButtonGroup } from "react-bootstrap";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { common } from "@mui/material/colors";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteOutlined, VisibilityOutlined } from "@mui/icons-material";
import ViewProductDetail from "./ViewProductDetail";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MessageApiService } from "../../Service/MessageApiService";

const ViewOfferRequest: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [offerRequest, setOfferRequest] = useState<any>(null);
  const [offerRequestDocs, setOfferRequestDocs] = useState<any[]>([]);
  const [offerRequestHistoryData, setOfferRequestHistoryData] = useState<any[]>([]);
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "",companyId: "", ownerStatus: "", ownerRejectionNote: ""});
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [isOwnerStatusChange, setIsOwnerStatusChange] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const handleViewClose = () => setViewDetails(prev => !prev);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);

  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
  };
  const [error, setError] = useState<string>("");

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const routeBack = () => {
    if (CommonService.isUser()) history.push("/user/offer-request");
    else history.push("/master/offer-request");
  };

  useEffect(() => {
    fetchData();
    if(CommonService.isAdmin())
      getAdminUnreadMessageGroupsCount();
  }, []);

  const fetchData = async () => {
    try {
      fetchOfferRequestDetail();
      fetchOfferRequestDocs();
      fetchOfferRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOfferRequestDetail = async () => {
    const response: any = await OrderApiService.fetchOfferRequestById(id);
    setOfferRequest(response.data);
    const hasSeller = CommonService.isSameCompany(response.data?.typeSenseResponse?.company_id) && CommonService.isUser() 
                      ? true : false;
    setIsSeller(hasSeller);
  };

  const fetchOfferRequestDocs = async () => {
    const response: any = await OrderApiService.fetchOfferRequestDocs(id);
    setOfferRequestDocs(response.data);
  };

  const fetchOfferRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOfferRequestHistory(id);
    setOfferRequestHistoryData(response.data);
  };

  const getAdminUnreadMessageGroupsCount = async () => {
    try {
      const response = await MessageApiService.getAdminMessageGroups(id, "OFFER_REQUEST");

      setUnreadGroupsCount(response.data.unreadGroupsCount);
      setUnreadMessageCount(response.data.unreadMessageCount);
    } catch (error) {
      console.log("error fetching Admin Message Groups Count" + error);
    }
  }

  //handle edit button click
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox(prev => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(unreadGroupsCount > 0 && unreadMessageCount > 0 ? unreadGroupsCount - 1 : unreadGroupsCount);
  };

  const downloadOfferRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: offerRequest.siteId,
      companyId: offerRequest.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOfferRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

  const getVariant = (status: any) => {
    switch (status) {
      case "ACCEPTED":
        return "success";
      case "PENDING_REVIEW":
        return "primary";
      case "REJECTED":
        return "danger";
      case "CANCELLED":
        return "dark";
      case "SHIPPED":
        return "info";
      case "DELIVERED":
        return "secondary";
      default:
        return "secondary";
    }
  };

  const handleStatusChange = async (newStatus: string) => {

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      companyId: CommonService.getCompanyId(),
      ownerStatus:null,
      ownerRejectionNote:null,
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOfferStatus();
      fetchOfferRequestHistory();
    }
  };

  const changeOfferStatus = async () => {
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    }

    setIsLoading(true);
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      statusDetailsRef.current.companyId = CommonService.getCompanyId();
      
      await OrderApiService.changeOfferStatus(statusDetailsRef.current);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Offer Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchOfferRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleOwnerStatusChange = async (newStatus: string) => {

    let updatedStatus;

    if (newStatus === "Reject for Owner")
      updatedStatus = "Admin Rejected";
    else if (newStatus === "Accept for Owner")
      updatedStatus = "Admin Accepted";
    else if (newStatus === "Send to Owner")
      updatedStatus = "Pending Review";
    else
      updatedStatus = newStatus;    

    const status = updatedStatus?.replace(/ /g, "_");

    const details = {
        id: id,
        status:null,
        rejectionNote:null,
        ownerStatus: status.toUpperCase(),
        ownerRejectionNote: rejectionNote.current,
        companyId: CommonService.getCompanyId()
    };

    statusDetailsRef.current = details;
    if (statusDetailsRef.current.ownerStatus === Constant.OrderStatus.REJECTED || statusDetailsRef.current.ownerStatus === Constant.OrderStatus.ADMIN_REJECTED) {
        setIsOwnerStatusChange(true); // to call method of order item status change
        handleShow();
    } else {
        changeOwnerStatus();
    }
  };

  const changeOwnerStatus = async () => {

    if((statusDetailsRef.current.ownerStatus === Constant.OrderStatus.REJECTED || statusDetailsRef.current.ownerStatus === Constant.OrderStatus.ADMIN_REJECTED) && rejectionNoteValue.trim().length == 0){
      setError("Please enter rejection note");
      return ;
    }

    setIsLoading(true);
    
    try {
      statusDetailsRef.current.ownerRejectionNote = rejectionNote.current;

      await OrderApiService.changeOwnerStatusForOffer(statusDetailsRef.current);
      setIsLoading(false);
      setIsOwnerStatusChange(false); 

      CommonService.Toast.fire({
          title: "Owner status updated successfully",
          icon: "success",
      });

      handleClose();
      fetchOfferRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadOfferRequestDOC(params.row)}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="usersList position-relative">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
          {CommonService.isAdmin() && (
            <div className="back__button me-3" onClick={routeBack}>
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-3">
              <h1 className="title mb-0">
                Offer Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
          <div className="time__details me-4 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Request number
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {offerRequest?.requestNumber}
              </Typography>
            </div>
            {(CommonService.isSameCompany(offerRequest?.companyId) ||
              CommonService.isAdmin()) && (
              <>
                {" "}
                <div className="company__details me-4 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Company name
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "#333333",
                      fontSize: { xl: "18px", xs: "16px" },
                      fontWeight: 700,
                    }}
                  >
                    {offerRequest?.companyName}
                  </Typography>
                </div>
                <div className="person__details me-4 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Submitted by
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {offerRequest?.firstName}
                  </Typography>
                </div>
              </>
            )}
            <div className="time__details me-4 me-xl-4 mb-0">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                { !isSeller ? CommonService.formatDateTime(offerRequest?.createdDate) 
                            : CommonService.formatDateTime(offerRequestHistoryData[offerRequestHistoryData.length - 1]?.histories[offerRequestHistoryData[offerRequestHistoryData.length - 1].histories.length - 1]?.createdDate)}
             
              </Typography>
            </div>
          </div>

          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              <IconButton
                aria-label="message"
                size="small"
                onClick={CommonService.isAdmin() ? handleAdminMessageClick : handleMessageBoxClick}
                sx={{
                  borderRadius: "50%",
                  backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                  color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                  marginInline: "8px",
                  padding: "8px",
                }}
              >
                <MarkunreadOutlinedIcon />
                {unreadGroupsCount != 0 && CommonService.isAdmin() && <div className="msg-count-wrapper">
                  {unreadGroupsCount}
                </div>}
                {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
              </IconButton>
              {showMessageBox &&
                <div className="admin-message-box">
                  <div className="admin-message-box-left-inner" onClick={handleAdminMessageClick}></div>
                  <div className="admin-message-box-inner positio-relative">
                    <AdminMessageBox selectedGroup={""} requestId={id} 
                      requestType={"OFFER_REQUEST"} unreadGroupsCount={unreadGroupsCount}
                      setUnreadGroupsCount={setUnreadGroupsCount} isSeller={isSeller} />
                  </div>
                </div>
              }
              {/* Pending Review - User */}
              {(offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "ACCEPTED_REJECTED") && CommonService.isUser() && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isAdmin() || !CommonService.isSameCompany(offerRequest?.companyId)}
                  allowedLabels={["Cancelled"]}
                />
              )}

              {/* Pending Review or Under Review - Admin */}
              {((offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "UNDER_REVIEW") && CommonService.isAdmin()) && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser() || offerRequest?.status === "CANCELLED" || offerRequest?.status === "REJECTED" || (offerRequest?.status === "UNDER_REVIEW" && offerRequest?.hasValidStatus)}
                  allowedLabels={offerRequest?.hasValidStatus ? ["Under Review"] : offerRequest?.hasItemRejected ? ["Under Review", "Rejected"] : ["Under Review","Accepted", "Rejected"]}
                />
              )}

              {/* Under Review - User (disabled) */}
              {(offerRequest?.status === "UNDER_REVIEW" && CommonService.isUser()) && (
                  <StatusDropdown
                    currentStatus={formatStatus(offerRequest?.status)}
                    onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                    disableCondition={true}
                    allowedLabels={[]}
                  />
              )}

              {/* Accepted to Payment Received - Both User and Admin */}
              {["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED"].includes(offerRequest?.status) && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser() || offerRequest?.status === "CANCELLED" || offerRequest?.status === "REJECTED"}
                  allowedLabels={["Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received", "Rejected"]}
                />
              )}

              {offerRequest?.status === "PAYMENT_RECEIVED" && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Ownership Transferred", "Rejected"]}
                />
              )}

              {(offerRequest?.status === "CANCELLED" ||
                offerRequest?.status === "OWNERSHIP_TRANSFERRED") && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={true}
                  allowedLabels={[]}
                />
              )}

              {offerRequest?.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${offerRequest?.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="viewSampleRequest--wrapper view_request--wrapper viewOrderRequest--wrapper viewOfferRequest--wrapper">
          <div
            className={`${
              isOpenEditSection
              ? "margin-right-400"  // When only `isOpenEditSection` is true
              : isMessageBoxOpen
              ? "margin-right-400"   // Only `isMessageBoxOpen` is true
              : "no-margin"    
            }`}
          >
            <div className="view-sample-request mb-3">
              {/* <div className="row m-0">
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Inventory
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {offerRequest?.inventoryName}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Quantity
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {offerRequest?.quantity} barrels
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Price
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      <CurrencyFormatter
                        amount={offerRequest?.pricePerBarrel}
                        locale="en-US"
                        currency="USD"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Total Price
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      <CurrencyFormatter
                        amount={offerRequest?.totalAmount}
                        locale="en-US"
                        currency="USD"
                      />
                    </div>
                  </div>
                </div>
                {(CommonService.isSameCompany(offerRequest?.companyId) ||
                  CommonService.isAdmin()) && (
                  <>
                    {" "}
                    <div className="col-sm-6 col-lg-3">
                      <div className="sample-request-faq py-3 h-100">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Handling Fee
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          <CurrencyFormatter
                            amount={offerRequest?.handlingFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="sample-request-faq py-3 h-100">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Service Fee
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          <CurrencyFormatter
                            amount={offerRequest?.serviceFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-12 col-lg-6 d-flex">
                  <div className="row w-100">
                    <div className="col-sm-6">
                      <div className="sample-request-faq py-3">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Seller
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          {offerRequest?.typeSenseResponse
                            ?.is_relocation_required ? (
                            <>
                              <span className="d-flex my-1">
                                <LocalShippingOutlinedIcon
                                  sx={{ color: "#9E874A", mr: 1 }}
                                />
                                Relocation Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={ShippingCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Relocation not Required
                              </span>
                            </>
                          )}
                          {offerRequest?.typeSenseResponse
                            ?.is_account_transfer ? (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferIcon}
                                  alt="AccountTransferIcon"
                                  className="me-2"
                                  width={28}
                                  height={24}
                                />
                                Change of Account Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Change of Account not Required
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="sample-request-faq py-3">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Buyer
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          {offerRequest?.isRelocationRequired ? (
                            <>
                              <span className="d-flex my-1">
                                <LocalShippingOutlinedIcon
                                  sx={{ color: "#9E874A", mr: 1 }}
                                />
                                Relocation Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={ShippingCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Relocation not Required
                              </span>
                            </>
                          )}
                          {offerRequest?.isAccountTransfer ? (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferIcon}
                                  alt="AccountTransferIcon"
                                  className="me-2"
                                  width={28}
                                  height={24}
                                />
                                Change of Account Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Change of Account not Required
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                            </div> 
              </div>  */}
              <div className="row m-0 pt-3">
              <div className="col-12 px-0">
                <div>
                  <div>
                    <div className="inventory-file-list-table">
                        <div className="table-responsive-lg table-responsive-fixed-header mb-lg-3">
                        {(!offerRequest) ? (
                  <Skeleton
            animation="wave"
            variant="rounded"
            // height={550}
            style={{ height: "100%" }}
                  />
                ):(
                          <Table className='orderRequest-inv-table text-center'>
                            <thead>
                              <tr>
                                <th className='text-nowrap text-start'>Inventory</th>
                                { !isSeller &&
                                <>
                                  <th colSpan={2} className="text-center text-nowrap">Seller</th>
                                  <th colSpan={2} className="text-center text-nowrap">Buyer</th>
                                </>}
                                <th className='text-nowrap text-center'>Total Qty.</th>
                                <th className='text-nowrap text-center'>Price</th>
                                { !isSeller &&
                                <th colSpan={2} className="text-center text-nowrap">Fees</th>}
                                <th className='text-nowrap text-center'>Total Price</th>
                                {( isSeller || CommonService.isAdmin()) &&
                                <th className='text-nowrap text-center'>Owner Status</th>}
                                <th className='text-nowrap text-center'>Action</th>
                              </tr>
                              <tr>
                                <th className='text-nowrap text-center'></th>
                                { !isSeller && <>
                                <th className='text-nowrap text-center'>Relocation</th>
                                <th className='text-nowrap text-center'>Cha. of Acc.</th>
                                <th className='text-nowrap text-center'>Relocation</th>
                                <th className='text-nowrap text-center'>Cha. of Acc.</th>
                                </>}
                                <th className='text-nowrap text-center'></th>
                                <th className='text-nowrap text-center'></th>
                                { !isSeller &&<>
                                <th className='text-nowrap text-center'>Handling</th>
                                <th className='text-nowrap text-center'>Services</th>
                                </>}
                                <th className='text-nowrap text-center'></th>
                                {( isSeller || CommonService.isAdmin()) && <th className='text-nowrap text-center'></th>}
                                <th className='text-nowrap text-center'></th>
                              </tr>
                            </thead>
                            <tbody>
                            {offerRequest ? (<tr>
                                <td className="text-start">{offerRequest?.inventoryName}
                                <Typography component="p" sx={{ color: "#737376", fontSize: "13px", fontWeight: "400", lineHeight:"21.28px" }}>
                                    {offerRequest?.inventoryOwnerCompanyName}
                                </Typography>
                                </td>
                                { !isSeller && <>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.typeSenseResponse?.is_relocation_required ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.typeSenseResponse?.is_account_transfer ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.isRelocationRequired ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                <td className='text-nowrap text-center'>
                                  {offerRequest?.isAccountTransfer ? (
                                    <>
                                      <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                    </>
                                  ) : (
                                    <>
                                      <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                    </>
                                  )}
                                </td>
                                </>}
                                <td className='text-nowrap text-center'>{offerRequest?.quantity} barrels</td>
                                <td className='text-nowrap text-center'>
                                  <CurrencyFormatter
                                    amount={offerRequest?.pricePerBarrel}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </td>
                                { !isSeller && <>
                                <td className='text-nowrap text-center'><CurrencyFormatter
                                  amount={offerRequest?.handlingFees}
                                  locale="en-US"
                                  currency="USD"
                                />
                                </td>
                                <td className='text-nowrap text-center'>
                                  <CurrencyFormatter
                                    amount={offerRequest?.serviceFees}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </td>
                                </>}
                                <td className='text-nowrap text-center'>
                                  <CurrencyFormatter
                                    amount={!isSeller ? offerRequest?.totalAmount
                                      : (offerRequest?.totalAmount - (offerRequest?.handlingFees + offerRequest?.serviceFees))}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </td>
                                {(isSeller || CommonService.isAdmin()) && <> 
                                <td className='text-nowrap text-center'>
                                  {CommonService.isAdmin() && <StatusDropdown currentStatus={formatStatus(offerRequest?.ownerStatus)}
                                        onStatusChange={(newStatus) =>
                                          handleOwnerStatusChange(newStatus)
                                        }
                                        disableCondition={CommonService.isSameCompany(offerRequest?.companyId) || ["REJECTED", "ADMIN_REJECTED", "ACCEPTED"].includes(offerRequest?.ownerStatus)
                                            || ["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "CANCELLED"].includes(offerRequest?.status)
                                        }
                                        allowedLabels={(offerRequest?.ownerStatus === "ADMIN_ACCEPTED") ? ["Reject for Owner"] 
                                                        : offerRequest?.ownerStatus === "PENDING_REVIEW" ? ["Reject for Owner", "Accept for Owner"] 
                                                        :["Reject for Owner", "Accept for Owner", "Send to Owner"]}/>}
                                  
                                  {CommonService.isUser() && <StatusDropdown currentStatus={formatStatus(offerRequest?.ownerStatus)}
                                            onStatusChange={(newStatus) =>
                                              handleOwnerStatusChange(newStatus)
                                            }
                                            disableCondition={CommonService.isSameCompany(offerRequest?.companyId) || ["REJECTED", "ADMIN_REJECTED"].includes(offerRequest?.ownerStatus)
                                                || ["ACCEPTED", "REJECTED", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED", "PAYMENT_RECEIVED", "OWNERSHIP_TRANSFERRED", "CANCELLED"].includes(offerRequest?.status)
                                            }
                                            allowedLabels={offerRequest?.ownerStatus === "ADMIN_ACCEPTED" ? ["Rejected"] : ["Accepted", "Rejected"]}/>}

                                  {(offerRequest?.ownerStatus === "REJECTED" || offerRequest?.ownerStatus === "ADMIN_REJECTED") && (
                                          <Tooltip
                                          title={`Rejection Note: ${offerRequest.ownerRejectionNote}`}
                                          arrow
                                          >
                                          <StickyNote2Icon
                                              className="note-icon cursor-pointer"
                                              style={{ color: "gray" }}
                                          />
                                          </Tooltip>
                                  )}
                                </td>
                                </>}
                                <td className='text-nowrap text-center'>
                                  <div className='d-flex justify-content-center gap-3' style={{ cursor: 'pointer' }}>
                                    {(CommonService.isAdmin() 
                                      && (offerRequest?.status === "PENDING_REVIEW" || offerRequest?.status === "UNDER_REVIEW")) 
                                      && <BorderColorIcon  onClick={handleEditButtonClick} /> 
                                    }
                                    <VisibilityOutlined onClick={() => { handleViewClose(); }} />
                                  </div>
                                </td>
                              </tr>
                              ):(<tr>
                                <td colSpan={12} style={{ textAlign: 'center' }}>Fetching records ...</td>
                              </tr>)}
                            </tbody>
                          </Table>
                )}
                        </div>
                        {viewDetails &&
                          <ViewProductDetail handleViewClose={handleViewClose} inventoryId={offerRequest?.inventoryId}/>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row mt-4 mb-3 m-0">
                <div className="col-xl-6 mb-3 mb-xl-0 ps-0">
                  <div className="orderDetail-footer-content-wrapper">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-0">List of Documents</h3>
                    <UploadDocs
                      fetchData={fetchData}
                      csvId={id}
                      uploadCSV={false}
                      requestType={"OFFER_REQUEST"}
                    />
                  </div>
                  <div
                    className="inventory-doc-table"
                    style={{ height: 350, width: "100%" }}
                  >
                    {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            localeText={localeText}
            rows={offerRequestDocs}
            columns={columnsForDoc}
            className="data-grid"
            hideFooterPagination
          /></>)}

                    
                  </div>
                  </div>
                </div>
                <div className="col-xl-6 mb-3 mb-xl-0 pe-0">
                  <div className="recentActivity">
                    <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-2">Recent Activity</h3>
                    </div>
                    <div className="history-stepper-wrapper">
                      <HistoryStepper historyData={offerRequestHistoryData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5 mb-3 m-0">
              <div className="col-xl-6 mb-3 mb-xl-0 px-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"OFFER_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={offerRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0 px-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                  <HistoryStepper historyData={offerRequestHistoryData} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
            <div className="msgContainerBox">
              {isOpenEditSection && CommonService.isAdmin() && (
                <EditRequest
                  requestType={"OFFER_REQUEST"}
                  id={id} //reload the date
                  refreshData={fetchData}
                  onEditClick={handleEditButtonClick}
                />
              )}
              {isMessageBoxOpen && (
                <ContainerMessageBox
                  requestType={"OFFER_REQUEST"}
                  sellerCompanyId={isSeller ? CommonService.getCompanyId() : null}
                  isSeller={isSeller}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Status Change Model Pop-up */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
        {isOwnerStatusChange ? <Modal.Title>Update Owner Status</Modal.Title>
                                  : <Modal.Title>Update Offer Request Status</Modal.Title>}
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>

        <Modal.Body className="pb-1">
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleInputChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter Rejection Note..."
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={isOwnerStatusChange ? changeOwnerStatus : changeOfferStatus}
                disabled={isLoading}
              >
                {isLoading && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewOfferRequest;
